//             /  //          / // /// //    |  // // //           /  //
//            /  ////       /  //    \  //   |  //   \  //        /  ////
//           /  //  //     |  //      \  //  |  //    |  //      /  //  //
//          /  //    //    |  //       | //  |  //   /  //      /  //    //
//         /  /// // ///   |  //       | //  |  // // //       /  /// // ///
//        /  //_______ //  |  //      /  //  |  //  \  //     /  //_______ //
//       /  //       \  //  \  //    /  //   |  //   \  //   /  //       \  //
//       \_//         \__//  \__// // //     |__//    \__//  \_//         \__//
import './App.scss';
import App from './App';
import ReactDOM from 'react-dom';
import { Theme } from './misc/Theme';
import { Router } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { createBrowserHistory } from 'history';
import ErrorBoundary from './misc/ErrorBoundary';
import { store, storeContext } from './stores/Store';
import { ThemeProvider } from '@mui/material/styles';


export const history = createBrowserHistory()

export const DisplayDictionary = store.AppStore.DisplayDictionary

export const DisplayValue = store.AppStore.DisplayValue

export const AlertModal = store.AlertStore.AlertModal

export const CloseModal = store.AlertStore.Close

export const Alert = store.AlertStore.Alert


export default function Root () {
	return (
		<ThemeProvider theme={Theme}>
			<ErrorBoundary>
				<SnackbarProvider maxSnack={5}>
					<storeContext.Provider value={store}>
						<Router history={history}>

							<App />
							<store.AlertStore.RenderAlertModals />

						</Router>
					</storeContext.Provider>
				</SnackbarProvider>
			</ErrorBoundary>
		</ThemeProvider>
	)
}

ReactDOM.render(<Root />, document.getElementById('root'));

