import React from "react"
import QuestionnaireSelectField from "./QuestionnaireSelectField"

export default function QuestionnaireSelectFormSection ({
    formId,
    value,
    onSubmit = (questionnaireId) => {}
}) {

    const [questionnaireUid, setQuestionnaireUid] = React.useState(false)

    return (
        <form 
            id={formId} 
            className="form-fields" 
            onSubmit={(e) => {
                e.preventDefault()
                onSubmit(questionnaireUid)
            }}>
            <QuestionnaireSelectField value={value} onChange={(uid) => setQuestionnaireUid(uid)} />
        </form>
    )
}