import { Button, CircularProgress } from "@mui/material"
import { observer } from "mobx-react-lite"
import React from "react"
import { AlertModal, DisplayValue } from "../../../../..";
import { Enquiry } from "../../../../../models/Enquiry";
import { store } from "../../../../../stores/Store";
import EnquiryUpdateSectionForm from "../../../../form/enquiry/EnquiryUpdateSectionForm";
import Help from "../../../../../misc/Help";
import { Theme } from "../../../../../misc/Theme";


interface EnquirySectionProps {
    enquiry: Enquiry;
    editable?: boolean;
    showCertainty?: boolean;
    maxHeight?: string;
}


export default observer(function EnquiryStatusSection ({enquiry, editable = true, showCertainty = true, maxHeight = "none"}: EnquirySectionProps) {

    var {sessionState} = store.AppStore
    var statusesSection = enquiry?.childrenObjects?.find(c => c.name === "STATUSES")

    const UpdateForm = () => {
        if (statusesSection) {
            AlertModal({title: "Statuses to Evaluate", body: <EnquiryUpdateSectionForm enquiryUid={enquiry.uid} section={statusesSection} />})
        }
    }

    var statuses = enquiry.childrenObjects.find(c => c.name === "STATUSES")?.childrenObjects
    var sections = []
     
    // Restructure to list of sections
    statuses.filter(s => s.selected).forEach(status => {
        
        var existingSection = sections.find(s => s.type === status.type)
        
        if (existingSection) {
            existingSection.rows.push(status)
        } else {
            sections.push({
                label: status.type,
                type: status.type,
                rows: [status]
            })
        }
    })

    if (store.AppStore.checkJwtRoles(["client_account", "sponsor_account"], false)) return <></>

    return (
        <div>
            <div className="section-header">
                <div className="header-content">
                    <h4>Statuses evaluated</h4>
            
                    {editable && !sessionState ? <Button className="btn btn-sm" onClick={() => UpdateForm()}>Edit</Button> : null}
                </div>
            </div>

            <div className="detail-section" style={{overflowY: "auto", maxHeight: maxHeight}}>            
                {sections.map((section, i) => (
                    <React.Fragment key={i}>
                        {sections.length > 1 ? (
                            <div className="section-header section-subheader">
                                <h5>{DisplayValue("nationality_type", section.label)}</h5>
                            </div>
                        ) : null}
                        
                        <div className="section-table">
                            {section.rows.filter(s => s.selected === true).map((status, i) => <Status key={i} status={status} enquiryStatus={enquiry.status} showCertainty={showCertainty} />)}
                        </div>
                    </React.Fragment>
                ))}
            </div>
        </div>
    )
})


export function Status ({status, enquiryStatus, showCertainty}) {

    var value
    var valueIsValid
    var valueDesciption

    switch (enquiryStatus) {
        case "INTERIM":
        case "DEFINITIVE":
        case "REPORT_DATA_REQUIRED":
            valueIsValid = true
            value = status.value
            break
        case "FINAL":
            valueIsValid = true
            value = status.lastInterimValue
            break
        case "RERUN_REQUIRED":
            valueIsValid = false
            value = status.lastDefinitiveValue
            valueDesciption = "This definitive outcome had previously been reached but was then made invalid by relevant data being changed"
            break
        default:
            value = "Unknown"
            valueIsValid = true
            valueDesciption = "An outcome has not yet been reached"
    }

    var label = DisplayValue("outcome", value)
    var color = store.EnquiryStore.outcomeColor(value)
    var Icon = () => store.EnquiryStore.outcomeIcon(value)
    
    return (
        <div className="section-row attribute-row">
            <div className="section-cell">{status.label}</div>

            <div className="section-cell" style={{width: "120px"}}>
                <div className="font-md" style={{color: color, fontWeight: 600, alignItems: "center", gap: "4px", textDecoration: !valueIsValid ? "line-through" : ""}}>
                    {valueDesciption ? label : <Help content={label}>{valueDesciption}</Help>}
                    {/* <Icon /> */}
                </div>
            </div>
            
            {showCertainty ? (
                <div className="section-cell" style={{maxWidth: "70px", width: "100%"}}>
                    <span style={{ margin: "0 10px 0 auto", color: Theme.palette.info.contrastText}}>
                        <CertaintyLabel certainty={status.certainty} />
                    </span>
                </div>
            ) : null}
        </div>
    )
}


const CertaintyLabel = ({certainty}) => {
        
    if (store.AppStore.sessionState?.includes("Running")) {
        return (
            <Help content={<CircularProgress style={{width: "10px", height: "10px"}} />}>
                This nationality is currently being evaluated
            </Help>
        )
    } 
    
    return DisplayValue("certainty", certainty)
}