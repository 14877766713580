import React from "react"
import Ajax from "../../Ajax"
import { store } from "../../stores/Store"
import Field from "./fields/Field"
import { Button } from "@mui/material"
import { Alert } from "../.."

export default function PopupRequestForm ({pr}) {

    const freeText = pr.validResponses.find(option => option === "TEXT")

    const [freeTextValue, setFreeTextValue] = React.useState("")
    const [submitting, setSubmitting] = React.useState(false)
    const [error, setError] = React.useState(null)

    const submitValue = (value) => {
        setError(false)
        setSubmitting(true)
        Ajax.Message.RespondPopup(value).catch((response) => {
            Alert({message: response.data?.detail ? response.data?.detail : "There was an error"})
        }).finally(() => {
            store.AlertStore.Close()
        })
    }

    return (
        <form className="form" onSubmit={(e) => {
            e.preventDefault()
            if (freeText) {
                if (freeTextValue) {
                    submitValue(freeTextValue)
                } else {
                    setError("Please provide a value")
                }
            }
        }}>

            {pr.title ? <h4>{pr.title}</h4> : null}
            
            <div dangerouslySetInnerHTML={
                { __html: pr.mainText }
            } />

            <div>

                {freeText ? (
                    <div className="form-fields">
                        <Field.Text 
                            formik
                            autoFocus
                            value={freeTextValue} 
                            placeholder="Enter text here..." 
                            onChange={(e) => {
                                setError(null)
                                setFreeTextValue(e.target.value)
                            }} />
                    </div>
                ) : null}

                {error ? <div className="warning">{error}</div> : null}
            </div>
            
            <div className="form-buttons">
                {freeText ? (
                    <Button 
                        disabled={submitting} 
                        className="btn btn-sm" 
                        variant="contained" 
                        onClick={() => submitValue(freeTextValue)}>
                        Submit
                    </Button>
                ) : null}

                {pr.validResponses.filter(o => o !== "TEXT")?.map((option, i) => (
                    <Button 
                        key={i} 
                        disabled={submitting} 
                        className="btn btn-sm" 
                        variant="contained" 
                        onClick={() => submitValue(option)}>
                        {option}
                    </Button>
                ))}
            </div>

        </form>
    )
}