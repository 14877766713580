import AutomationMenuOptions from "./AutomationMenuOptions"
import { Theme } from "../../../../../misc/Theme"
import AutomationReviewDetails from "./AutomationReviewDetails"
import AutomationResumeDetails from "./AutomationResumeDetails"
import AutomationAnwers from "./AutomationAnwsers"
import React from "react"
import { Tab, Tabs } from "@mui/material"


export default function AutomationDetail ({automationNode}) {

    var outputData = automationNode.serialisedInstance
    outputData = JSON.parse(outputData)

    const [tab, setTab] = React.useState<"details" | "answers">("details")

    var lastPage = outputData?.submitted_pages.length ? outputData?.submitted_pages[outputData?.submitted_pages.length - 1] : null

    return (        
        <div className="automation-review">
            <div className="header">
                <h2>Automation Review</h2>
                <AutomationMenuOptions automationNode={automationNode} />
                <Tabs
                    value={tab}
                    textColor="primary"
                    indicatorColor="primary"
                    className="automation-review-tab">
                    <Tab onClick={() => setTab("details")} value="details" label="Details" />
                    <Tab onClick={() => setTab("answers")} value="answers" label="Answers" />
                </Tabs>
            </div>

            <div className="automation-review-columns">
                <div  className={tab === "details" ? "form" : "form collapse"}>
                    
                    <div className="form-fields">
                        <div className="form-fields row">
                            <div>
                                <p>{automationNode.label}</p>
                                <p>Filled {outputData.current_page + 1} out of {outputData.total_pages} pages.</p>
                                <AutomationReviewDetails automationNode={automationNode} />
                            </div>
                            
                            <div>
                                <h4 style={{margin: "8px 0"}}>Resume details</h4>
                                <AutomationResumeDetails automationNode={automationNode} />
                            </div>
                        </div>
                        
                        {outputData?.validation_messages?.length ? (
                            <div>
                                <h4 style={{margin: "8px 0", color: Theme.palette.error.main}}>Invalid data</h4>
                                <small>The automation got stuck on "{lastPage?.title}" page because of validation errors:</small>
                                {outputData?.validation_messages.map((message, i) => (
                                    <small key={i} className="warning" style={{marginLeft: "20px", display: "list-item"}}>{message}</small>
                                ))}
                            </div>
                        ) : null}

                        {outputData.profile.questionnaires ? (
                            <div>
                                <h4 style={{margin: "8px 0"}}>Questionnaires used</h4>
                                {outputData.profile.questionnaires.map((qRef, i) => (
                                    <small key={i} style={{marginLeft: "20px", display: "list-item"}}>{qRef.label}</small>
                                ))}
                            </div>
                        ) : null}
                    </div>
                </div>

                <div className="divider"></div>

                <div  className={tab === "answers" ? "" : "collapse"}>
                    <AutomationAnwers automationNode={automationNode} />
                </div>
            </div>
        </div>
    )
}