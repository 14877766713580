import React from "react"
import * as Yup from 'yup'
import { Button } from "@mui/material"
import { onlineForms } from "../../../misc/Online Forms"
import { useFormik } from "formik"
import { store } from "../../../stores/Store"
import Field from "../fields/Field"


export default function AutomationConfirmFormSection ({
    formId,
    additionalData,
    hidden = true,
    onCancel = () => {},
    onSubmit = (values) => {}
}) {

    var form = onlineForms.find(f => f.id === formId)

    // Because this whole component is mounted before additional data is populated with the subject's email,
    // we need to update the formik value afterwards when the props update.
    React.useEffect(() => {
        if (additionalData?.subject_email) {
            formik.setFieldValue("subject_email", additionalData?.subject_email)
        }
    }, [additionalData?.subject_email])
    
    var formik = useFormik({
        initialValues: {
            advisor_email: store.AppStore.sessionInfo.userEmail,
            subject_email: additionalData?.subject_email,
        },
        validationSchema: Yup.object({
            advisor_email: Yup.string().matches(/\b[\w\.-]+@[\w\.-]+\.\w{2,4}\b/, "A valid email is required").required("This field is required"),
            subject_email: Yup.string().matches(/\b[\w\.-]+@[\w\.-]+\.\w{2,4}\b/, "A valid email is required").required("This field is required"),
        }),
        onSubmit: (values) => {
            onSubmit(values)
        }
    })

    if (hidden) return <></>

    return (
        <form className="form" onSubmit={formik.handleSubmit}>
            <div className="form-fields">
                <div>
                    <p>{form.name}</p>
                    <p>Correspondence with the Home Office will go via the email addresses entered here.</p>
                </div>

                <div style={{maxWidth: "360px"}}>
                    <Field.Text
                        formik
                        label="Advisor email"
                        name="advisor_email"
                        value={formik.values.advisor_email}
                        warning={formik.submitCount && formik.errors.advisor_email ? formik.errors.advisor_email : null}
                        onChange={formik.handleChange}  />

                    <Field.Text
                        formik
                        label="Applicant email"
                        name="subject_email"
                        helpText="Enter the applicant's or preferred alternative email address to be used in the form."
                        value={formik.values.subject_email}
                        warning={formik.submitCount && formik.errors.subject_email ? formik.errors.subject_email : false}
                        onChange={formik.handleChange}  />
                </div>
                
                {/* <div>
                    <div className="help-text" style={{display: "grid", gridTemplateColumns: "220px auto"}}>
                        {additionalData?.id_check_document_type ? (
                            <>
                                <span>ID check document type</span>
                                <span>{additionalData.id_check_document_type}</span>
                                <span>ID check document number</span>
                                <span>{additionalData.id_check_document_number}</span>
                                <span>ID check document date of birth</span>
                                <span>{additionalData.id_check_document_date_of_birth}</span>
                            </>
                        ) : null}
                    </div>
                </div> */}
            </div>

            <div className="form-buttons">
                <Button className="btn btn-sm" onClick={() => onCancel()}>Back</Button>
                <Button className="btn btn-sm" type="submit" color="success" variant="contained">
                    Confirm and start automation
                </Button>
            </div>
        </form>
    )
}