export const dict_key = {
    "accountName": "Account name",
    "parentAccount": "Parent account",
    "parent": "Parent",
    "label": "Label",
    "displayName": "Name",
    "accountType": "Account type",
    "customerType": "Customer type",
    "organisationType": "Organisation type",
    "addressLine1": "Address line 1",
    "city": "City",
    "postcode": "Postcode",
    "countryId": "Country ID",
    "status": "Status",
    "regulator": "Regulator",
    "regulatorRegistrationNumber": "Regulator registration number",
    "lastActive": "Last active",
    "createdOn": "Created on",
    "createdBy": "Created by",
    "notificationEmail": "Advisor email",
    "owner": "Owner",
    "details": "Details",
    "uid": "UID",
    "createdAt": "Created at",
    "updatedAt": "Updated at",
    "template": "Template",
    "respondent": "Respondent",
    "sponsorLicenceNum": "Sponsor licence number",
    "sponsorExpiryDate": "Sponsor expiry date",
    "sponsorStartDate": "Sponsor start date"
}
