import React from 'react'
import Ajax from '../../Ajax'
import { AlertModal } from '../..'
import { Icons } from '../../misc/Icons'
import Field from '../form/fields/Field'
import { observer } from 'mobx-react-lite'
import { store } from '../../stores/Store'
import { Button, CircularProgress, Tab, Tabs } from '@mui/material'


export default observer(function SystemInformationModal () {
    return (
        <div 
            onClick={() => AlertModal({body: <SystemInformation />, width: "600px"})}
            className="font-grey font-sm" 
            style={{display: "grid", gridTemplateColumns: "50px auto", gridColumnGap: "5px", textAlign: "left", lineHeight: "16px"}}
        >
            <span>Session</span><span>{store.AppStore.sessionInfo.sessionId}</span>
            <span>Server</span><span>{store.AppStore.sessionInfo.instanceName}</span>
            <span>Version</span><span>{store.AppStore.sessionInfo.version}</span>
        </div>
    )
})


export const SystemInformation = observer(function () {

    const {ConnectionStore} = store
    const {stopHubConnection, createHubConnection, connectionState, hubConnection, testConnection, testCount, transportTypes, setTransportType, testsVerified, testComplete, transportType} = ConnectionStore
    const [serverInfo, setServerInfo] = React.useState(null)
    const tokenClaims = store.AppStore.decodeJWT()
    const [tab, setTab] = React.useState<"websockets" | "coordinator" | "jwt" | "server_info">("websockets")

    const getServerInfo = () =>
        Ajax.Session.SessionDebug().then((response) => setServerInfo(response.data))

    React.useEffect(() => {
        getServerInfo()
    }, [])

    if (!serverInfo) return <h4>Loading...</h4>

    return (
        <div style={{display: "grid", gap: "15px"}}>
            <h2>System information</h2>

            <Tabs
                value={tab}
                // centered={true}
                textColor="primary"
                indicatorColor="primary"
                >
                <Tab onClick={() => setTab("websockets")} value="websockets" label="Websockets" />
                <Tab onClick={() => setTab("coordinator")} value="coordinator" label="Middle layer state" />
                <Tab onClick={() => setTab("jwt")} value="jwt" label="JWT" />
                <Tab onClick={() => setTab("server_info")} value="server_info" label="Raw" />
            </Tabs>

            {tab === "websockets" ? (
                <div>
                    <p>Connection state: {connectionState ? connectionState : "Disconnected"}</p>
                    
                    <Field.Select warning={false} label="Select transport type" formik onChange={(e) => setTransportType(e.target.value)} value={transportType} options={Object.keys(transportTypes).map(key => {return {value: key, label: transportTypes[key]}})} />
                    
                    <p>The AORA app works by listening for messages from the server. You are currently {connectionState === "Connected" ? "connected and listening" : "disconnected and not listening"}.</p>
                        
                    <Field.Switch formik label="Listening" value={!!store.ConnectionStore.connectionState} warning={false} onChange={(e) => {
                        if (e.target.checked) createHubConnection()
                        else stopHubConnection()
                    }} />

                    <p>To make sure you are able to receive messages, you can run a connection test.</p>

                    <Button
                        className="btn btn-sm"
                        disabled={!testComplete}
                        variant="contained"
                        onClick={() => testConnection()} >
                        {testComplete 
                            ? <><Icons.Refresh />Run connection test</> 
                            : <><CircularProgress style={{width: "10px", height: "10px", margin: "4px"}} /> Running test</>}
                    </Button>

                    <p>{testsVerified} out of {testCount} test messages arrived successfully.</p>
                </div>
            ) : null}

            {tab === "coordinator" ? (
                <div>
                    <p>Pending question: {serverInfo.pending_question ? serverInfo.pending_question : ""}</p>
                    <p>Pending popup: {serverInfo.pending_popup ? serverInfo.pending_popup : ""}</p>
                    <p>Pending edit: {serverInfo.pending_edit ? "Yes" : ""}</p>
                    <p style={{display: "flex", gap: "10px"}}>
                        Session state: {serverInfo.your_session_state ? serverInfo.your_session_state : ""}
                        {serverInfo.your_session_state ? (
                            <Button className="btn btn-xs" onClick={() => Ajax.Message.Cancel().then(() => getServerInfo())}>
                                Reset state
                            </Button>
                        ) : null}
                    </p>
                </div>
            ) : null}

            {tab === "jwt" ? (
                <InfoTableSection title="Info from JWT" object={tokenClaims} />
            ) : null}

            {tab === "server_info" ? (
                <InfoTableSection title="Info from server" object={serverInfo} />
            ) : null}
        </div>
    )
})


function InfoTableSection ({title, object}) {

    return (
        <div style={{margin: "10px 0", overflow: "auto", maxHeight: "60vh"}}>
            {!object ? <p>No information available</p> : (
                <table style={{width: "100%", whiteSpace: "pre", padding: "0 6px"}}>
                    <tbody>
                        {Object.keys(object).map((key, i) => (
                            <tr key={i}>
                                <td>{key}</td>
                                <td>{JSON.stringify(object[key], null, "\t")}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    )
}