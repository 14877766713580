import React from "react";
import { observer } from "mobx-react-lite";
import { store } from "../../../../../stores/Store";
import { Button, CircularProgress } from "@mui/material";


export default observer(function ReportCta () {

    const [loading, setLoading] = React.useState(false)
    const { enquiry } = store.EnquiryStore
    const { openReportRequestForm } = store.ReportStore

    if (enquiry.status !== "DEFINITIVE") {
        return (
            <div className="section-subheader">
                <small>You must complete the enquiry before creating reports.</small>
            </div>
        )
    }

    return (
        <>
            <div className="section-subheader">
                <small>You may have some Report options.</small>
            </div>

            <Button
                color="success"
                className="btn btn-md"
                variant="contained"
                style={{margin: "8px"}}
                disabled={loading}
                onClick={async () => {
                    setLoading(true)
                    await openReportRequestForm().finally(() => setLoading(false))
                }}>
                {loading ? <CircularProgress /> : "Create Reports"}
            </Button>
        </>
    )
})