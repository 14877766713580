import Ajax from '../Ajax'
import { store } from './Store'
import { makeAutoObservable } from 'mobx'
import { InformationRequest } from '../models/InformationRequest'
import { NodeReference } from '../models/Interfaces';
import { Alert } from '..';


export class QuestionStore {
    
    informationRequest: InformationRequest | null = null;
    initialisationForm: "CREATE_PERSON" | null = null
    locatorPath: NodeReference[] = [];
    highlightUid: string = null;
    serverValidationMessage: string = "";
    provisionCount: number = 0;
    loading: boolean = true;

    constructor() {
        makeAutoObservable(this)
    }

    get showQuestionPanel (): boolean {
        var stateLowerCase = store.AppStore.sessionState?.toLowerCase()

        return (
            !!this.informationRequest ||
            stateLowerCase?.includes("running") ||
            stateLowerCase?.includes("creating person") ||
            (stateLowerCase?.includes("editing") && !!this.informationRequest) ||
            !!this.initialisationForm
        )
    }

    reset = () => {
        this.informationRequest = null
        this.initialisationForm = null
        this.serverValidationMessage = ""
        this.provisionCount = 0
        this.loading = true
    }

    initForm = (initialisationForm: "CREATE_PERSON" | null) => {
        
        this.initialisationForm = initialisationForm
        
        if (initialisationForm !== null) {
            store.AppStore.currentPanel = "QUESTION"
        }
    }

    handleRunInfo = (info: string) => {
        this.provisionCount = this.provisionCount + parseInt(info)
    }

    cancel = async () => {
        if (!this.informationRequest && this.initialisationForm) {
            this.initForm(null)
        } else {
            await Ajax.Message.Cancel().then(() => {
                this.informationRequest = null
                this.highlightUid = null
                this.locatorPath = []
            }).catch(() => Alert({message: "Failed to cancel"}))
        }
    }

    receiveInformationRequest = async (question: InformationRequest) => {

        this.initForm(null)
        this.serverValidationMessage = ""
        this.informationRequest = new InformationRequest(question)
        this.loading = false

        if (question.locatorPath?.nodeRefs?.length > 0) {

            this.locatorPath = question.locatorPath.nodeRefs

            // Navigate to question subject if there is a valid UID            
            var personUid = question.locatorPath.nodeRefs.at(0).uid // Should always be to the subject of the question
            if (personUid && personUid?.split("_").at(-1) !== "0" && window.innerWidth > 600) {
                console.log("Navigate to question subject", (personUid && personUid?.split("_").at(-1) !== "0" && window.innerWidth > 600))
                await store.NodeStore.navigateNode(personUid)
            }
        }

        if (!store.NodeStore.loading && store.AppStore.stateAction?.includes("Running")) {
            // await store.NodeStore.refreshFolderNode()
        }

        store.AppStore.currentPanel = "QUESTION"
        this.provisionCount = 0
    }

    submitAnswer = async (values, certainties) => {

        this.loading = true
        this.informationRequest = null
        this.locatorPath = []
        this.highlightUid = null
        this.serverValidationMessage = ""

        await Ajax.Message.Respond(values, certainties).catch((response) => {
            this.serverValidationMessage = response.data.title
        })
    }
}