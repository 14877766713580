import Chart from "chart.js/auto";
import { useEffect } from "react";


export default function FormAutomationChart ({chartData, colors, labels}) {

    const randomId = "canvas-container-" + Math.random()

    useEffect(() => {

        // Get empty container for canvas
        const canvasContainer = document.getElementById(randomId)
        canvasContainer.innerHTML = ""

        // Create and insert canvas element
        const canvas = document.createElement("canvas")
        canvasContainer.appendChild(canvas)

        // Render canvas chart
        const ctx = canvas.getContext("2d")
        new Chart(ctx, {
            type: 'pie',
            options: {
                events: []
            },
            data: {
                labels: false,
                datasets: [{
                    label: false,
                    data: chartData,
                    backgroundColor: colors,
                    borderWidth: 1
                }]
            }
        });
    })

    return (
        <div id={randomId}></div>
    )
}