import * as Yup from 'yup'
import { Button } from "@mui/material"
import Field from "../fields/Field"
import { format, isValid } from "date-fns"
import { useFormik } from "formik"
import { observer } from "mobx-react-lite"


interface AutomationIdCheckFormProps {
    hidden?: boolean;
    onCancel?: any;
    onSubmit?: any;
}


export default observer(function AutomationIdCheckForm ({
    hidden = true,
    onCancel = () => {},
    onSubmit = (values) => {}
}: AutomationIdCheckFormProps) {

    var formik = useFormik ({
        initialValues: {
            id_check_document_date_of_birth: "",
            id_check_document_type: "",
            id_check_document_number: ""
        },
        validationSchema: Yup.object({
            id_check_document_date_of_birth: Yup.date()
                .min(new Date("1900-01-01"), "Enter a valid date")
                .max(new Date(), "Date must be in the past")
                .typeError("Enter a valid date")
                .required("This field is required"),
            id_check_document_type: Yup.string().required("This field is required"),
            id_check_document_number: Yup.string().required("This field is required"),
        }),
        onSubmit: (values) => {
            console.log("onSubmit", values)
            onSubmit(values)
        }
    })

    if (hidden) return <></>

    return (
        <form className="form" onSubmit={formik.handleSubmit}>
            <div className="form-fields">
                <div>
                    <p>This Home Office application requires an ID check.</p>
                    <p>
                        To autofill Home Office applications which require an ID check, you must first start the application and complete the ID check <b>manually</b>. 
                        We recommend you follow <a href="https://www.aoralaw.com/added-value-swv/54-running-an-automation-with-an-id-check.php" className="link" target="_blank" rel="noreferrer">our guidance</a> on how to do this.
                    </p>
                    <p>Once you have done this, we can automate the rest.</p>
                    <p>In order to autofill the application form, AORA needs to know the details which you used to start the application.</p>
                </div>
                
                <Field.Radio
                    formik
                    name="id_check_document_type"
                    onChange={formik.handleChange}
                    value={formik.values.id_check_document_type}
                    warning={formik.submitCount && formik.errors.id_check_document_type ? formik.errors.id_check_document_type : null}
                    label="Document type" options={[
                        {value: "passport", label: "Passport"},
                        {value: "biometric_card", label: "Biometric residence permit"}
                ]} />

                <div style={{maxWidth: "320px"}}>
                    <Field.Text
                        formik
                        name="id_check_document_number"
                        onChange={formik.handleChange}
                        value={formik.values.id_check_document_number}
                        warning={formik.submitCount && formik.errors.id_check_document_number ? formik.errors.id_check_document_number : null}
                        label="Document number" />
                    
                    <Field.Date
                        label="Date of birth on document"
                        name="id_check_document_date_of_birth"
                        value={formik.values.id_check_document_date_of_birth}
                        warning={formik.submitCount && formik.errors.id_check_document_date_of_birth ? formik.errors.id_check_document_date_of_birth : false}
                        onChange={(e) => {
                            formik.setFieldValue("id_check_document_date_of_birth", e.target.value)
                        }} />
                </div>
            </div>

            <div className="form-buttons">
                <Button className="btn btn-sm" onClick={() => onCancel()}>Cancel</Button>
                <Button type="submit" className="btn btn-sm" color="success" variant="contained">Next</Button>
            </div>
        </form>
    )
})


export const AutomationIdCheckOTPForm = observer(function ({
    hidden = true,
    onCancel = () => {},
    onSubmit = (values) => {}
}: AutomationIdCheckFormProps) {

    var formik = useFormik ({
        initialValues: {
            id_check_code_recipient: ""
        },
        validationSchema: Yup.object({
            id_check_code_recipient: Yup.string().required("This field is required"),
        }),
        onSubmit: (values) => {
            console.log("onSubmit", values)
            onSubmit(values)
        }
    })

    if (hidden) return <></>

    return (
        <form className="form" onSubmit={formik.handleSubmit}>
            <div className="form-fields">
                <div>
                    <p>Shortly after the automation starts, an <b>OTP (one-time password)</b> will be sent to the selected recipient. Thereafter, AORA will prompt you for the OTP here in the app.</p>
                    <p>
                        Home Office applications with ID checks allow for an optional 'Helper' to be granted access from the beginning.
                        If the application has been set up correctly and the 'Helper' was assigned <b>your Advisor Phone and Email</b>, 
                        then select one of these options so you can handle the OTP yourself.
                    </p>
                </div>
                
                <Field.Radio
                    formik
                    name="id_check_code_recipient"
                    onChange={formik.handleChange}
                    value={formik.values.id_check_code_recipient}
                    warning={formik.submitCount && formik.errors.id_check_code_recipient ? formik.errors.id_check_code_recipient : null}
                    label="Receiver of OTP" 
                    options={[
                        {value: "Advisor SMS", label: "Advisor SMS"},
                        {value: "Advisor email", label: "Advisor email"},
                        {value: "Applicant SMS", label: "Applicant SMS"},
                        {value: "Applicant email", label: "Applicant email"},
                ]} />
            </div>

            <div className="form-buttons">
                <Button className="btn btn-sm" onClick={() => onCancel()}>Back</Button>
                <Button type="submit" className="btn btn-sm" color="success" variant="contained">Next</Button>
            </div>
        </form>
    )
})