export default function PlaceholderComponent ({elements = 10}) {

    var arr = []
    for (var i = 0; i < elements; i++) {
        arr.push("")
    }

    return (
        <div className="placeholder-component shimmer">
            {arr.map((el, i) => (
                <div key={i} className="placeholder-element"></div>
            ))}
        </div>
    )
}