import React from "react"
import { observer } from "mobx-react-lite"
import { IconButton, LinearProgress } from "@mui/material"
import { Icons } from "../../../misc/Icons"
import { store } from "../../../stores/Store"
import PanelBody from "../../layout/PanelBody"
import AccountSelect from "../main/AccountSelect"
import PanelHeader from "../../layout/PanelHeader"
import AccountMgmtDetails from "./AccountMgmtDetails"
import AccountMgmtChildren from "./AccountMgmtChildren"


export default observer(function AccountMgmtTool() {    

    const {account, getAccount, setTool, loading} = store.ToolStore
    
    React.useEffect(() => {
        if (!account) {
            getAccount()
        }
    }, [])
    
    return (
        <div className="panel panel-dark">
            <PanelHeader>
                <div className="header">
                    <h2>Account management</h2>
                    
                    <AccountSelect value={account?.uid} accounts={store.NodeStore.accounts} handleSelect={(uid) => getAccount(uid)} />
                </div>

                <IconButton style={{marginLeft: "auto"}} size="medium" onClick={() => setTool(null)}><Icons.X /></IconButton>
            </PanelHeader>

            <PanelBody>
                {!account || loading ? <LinearProgress color="primary" /> : (
                    <div style={{overflowY: "auto"}}>
                        <AccountMgmtDetails />

                        <AccountMgmtChildren />
                    </div>
                )}
            </PanelBody>
        </div>
    )
})