import { observer } from "mobx-react-lite";
import { store } from "../../../../../stores/Store";
import { Button, CircularProgress } from "@mui/material";
import { AlertModal } from "../../../../..";
import AutomationOptions from "./AutomationOptions";


export default observer(function AutomationCta () {

    var { enquiry } = store.EnquiryStore

    if (enquiry.status !== "DEFINITIVE") {
        return (
            <div className="section-subheader">
                <small>You must complete the enquiry before creating automations.</small>
            </div>
        )
    }

    var provision = store.EnquiryStore.getQualifyingProvision()
    var onlineForms = store.EnquiryStore.getQualifyingOnlineForms(provision?.rule_id)
    var automationInProgress = !!store.AutomationStore.pendingAutomations?.length

    if (!onlineForms?.length) {
        return (
            <div className="section-subheader">
                <small>You don't have any automation options.</small>
            </div>
        )
    }

    return (
        <>
            <div className="section-subheader">
                {automationInProgress ? (
                    <div style={{display: "flex", gap: "10px", justifyContent: "normal", alignItems: "center"}}>
                        <CircularProgress style={{width: "10px", height: "10px", margin: "0"}} />
                        <small>Automation in progress</small>
                    </div>
                ) : (
                    <small>We have found {onlineForms?.length > 1 ? `${onlineForms.length} online forms which are` : "an online form which is"} relevant to this enquiry.</small>
                )}
            </div>

            <Button
                color="success"
                className="btn btn-md"
                variant="contained"
                style={{margin: "8px"}}
                disabled={automationInProgress}
                onClick={async () => {
                    AlertModal({title: "Create Automation", width: "700px", body: (
                        <AutomationOptions onlineForms={onlineForms} />
                    )})
                }}>
                Create Automation
            </Button>
        </>
    )
})