import React from "react";
import Ajax from "../../../Ajax";
import { Button } from "@mui/material";
import { store } from "../../../stores/Store";
import EnquirySettingsFormSection from "./EnquirySettingsFormSection";
import EnquiryStatusesFormSection from "./EnquiryStatusesFormSection";


export default function EnquiryUpdateSectionForm ({enquiryUid, section}) {

    var [submitting, setSubmitting] = React.useState(false)
    var [errorMessage, setErrorMessage] = React.useState("")
    
    const submit = async (enquiryData) => {
        
        setSubmitting(true)
        setErrorMessage("")

        await Ajax.Enquiry.Edit(enquiryUid, enquiryData).then(() => {
            store.AlertStore.Close()
            store.NodeStore.refreshFolderNode()
            store.NodeStore.refreshLeafNode()
        }).catch((response) => 
            setErrorMessage(response.data.title)
        )

        setSubmitting(false)
    }

    var formId = Math.random().toString()

    const AlternateSectionForms = () => {
        switch (section.name) {
            case "STATUSES":
                return <EnquiryStatusesFormSection formId={formId} section={section} onSubmit={(section) => submit({statuses: section.childrenObjects})} />
            case "SETTINGS":
                return <EnquirySettingsFormSection formId={formId} section={section} onSubmit={(section) => submit({settings: section.childrenObjects})} />
        }
    }

    return (
        <div className="form">
            <AlternateSectionForms />

            {errorMessage ? <div className="form-warning warning">{errorMessage}</div> : null}

            <div className="form-buttons">
                <Button
                    form={formId}
                    type="submit"
                    color="primary"
                    variant="contained"
                    className="btn btn-sm"
                    disabled={submitting}>
                    Submit
                </Button>
            </div>
        </div>
    )
}