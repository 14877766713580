export const provisions = [
    {
        status_id: "UKLLR_SW_P",
        rule_id: "UK.D.33.1",
        section_name: "Appendix Skilled Worker of the Immigration Rules",
        form_id: "SWV",
        section_description: ""
    }, {
        status_id: "UKBC_P",
        rule_id: "UK.D.22.3.21",
        section_name: "BNA 1981 section 4C",
        form_id: "UKM",
        section_description: "P born before 1983 and would have become CUKC if acquisition by descent from mothers had been treated like that from fathers. "
    }, {
        status_id: "UKBC_P",
        rule_id: "UK.D.22.3.30",
        section_name: "BNA 1981 section 4H",
        form_id: "UKF",
        section_description: "Factual CUKC who would have automatically become British citizens from 1983 had their mother been married to their natural fathers at birth."
    }, {
        status_id: "UKBC_P",
        rule_id: "UK.D.22.3.31",
        section_name: "BNA 1981 section 4I",
        form_id: "UKF",
        section_description: "'Eligible non/former British national' who would have become a CUKC/automatically became a British citizen from 1983."
    }, {
        status_id: "UKBC_P",
        rule_id: "UK.D.22.3.12",
        section_name: "BNA 1981 Sch 2 para 5",
        form_id: "S1",
        section_description: "Stateless P who is eligible for CUKC registration under BNA 1964 s1 and thus would counterfactually have become a British citizen."
    }, {
        status_id: "UKBC_P",
        rule_id: "UK.D.22.3.26",
        section_name: "BNA 1981 section 13",
        form_id: "RS1",
        section_description: "P who has previously renounced their British citizenship to acquire/retain some other citizenship or nationality."
    }, {
        status_id: "UKBC_P",
        rule_id: "UK.D.22.3.27.1",
        section_name: "-",
        form_id: "EM",
        section_description: "Potentially stateless persons who was resident in Hong Kong and are British Overseas Territories citizens only through connection to Hong Kong  (British Nationality (Hong Kong) Act 1997 s1(2))"
    }, {
        status_id: "UKBC_P",
        rule_id: "UK.D.22.3.27.2",
        section_name: "-",
        form_id: "EM",
        section_description: "Potentially stateless person who was resident in Hong Kong who would be stateless if not for being a British Overseas Citizen/British Protected person/British subject. (British Nationality (Hong Kong) Act 1997 s1(3))"
    }, {
        status_id: "UKBC_P",
        rule_id: "UK.D.22.3.20",
        section_name: "BNA 1981 section 4B",
        form_id: "B(OS)",
        section_description: "Registration of British Overseas citizens, British Subjects, British Protected Persons or British Nationals with no other citizenship or nationality."
    }, {
        status_id: "UKBC_P",
        rule_id: "UK.D.22.3.23",
        section_name: "BNA 1981 section 8",
        form_id: "-",
        section_description: "Woman who was married to a man on 1 Jan 1983 who became a British citizen on that date (Defunct provision but tested counterfactually)."
    }, {
        status_id: "UKBC_P",
        rule_id: "UK.D.22.3.24",
        section_name: "BNA 1981 section 9",
        form_id: "-",
        section_description: "P born in a foreign country between 1983 and 1988 whose CUKC by descent father was resident there and married to P's mother at commencement (Defunct provision but tested counterfactually)."
    }, {
        status_id: "UKBC_P",
        rule_id: "UK.D.22.3.25",
        section_name: "BNA 1981 section 10",
        form_id: "RS1",
        section_description: "Registration of person who previously renounced their CUKC status (before 1983)."
    }, {
        status_id: "UKBC_P",
        rule_id: "UK.D.22.3.1",
        section_name: "BNA 1981 section 1(3)",
        form_id: "MN1",
        section_description: "Minor who was born in the UK and whose parent later becomes settled in the UK while they are a minor."
    }, {
        status_id: "UKBC_P",
        rule_id: "UK.D.22.3.2",
        section_name: "BNA 1981 section 1(3A)",
        form_id: "MN1",
        section_description: "Minor born in the UK whose parent becomes a member of the armed forces whilst they are a minor. "
    }, {
        status_id: "UKBC_P",
        rule_id: "UK.D.22.3.3",
        section_name: "BNA 1981 section 1(4)",
        form_id: "T",
        section_description: "P born in UK aged ten or over, who has been absent from the UK for <90 days during those ten years."
    }, {
        status_id: "UKBC_P",
        rule_id: "UK.D.22.3.7",
        section_name: "BNA 1981 section 4D",
        form_id: "MN1",
        section_description: "P with a parent who was a member of the armed forces serving outside the UK Territories at their birth."
    }, {
        status_id: "UKBC_P",
        rule_id: "UK.D.22.3.22",
        section_name: "BNA 1981 section 5",
        form_id: "-",
        section_description: "Registration of British Overseas Territories citizens who have that status by virtue of a connection with Gibraltar as British citizens."
    }, {
        status_id: "UKBC_P",
        rule_id: "UK.D.22.3.4.1",
        section_name: "BNA 1981 section 3(5)",
        form_id: "MN1",
        section_description: "Minor born outside UK to a British citizen by descent parent with <270 days absence within last 3 years."
    }, {
        status_id: "UKBC_P",
        rule_id: "UK.D.22.3.6",
        section_name: "BNA 1981 section 4A",
        form_id: "B(OTA)",
        section_description: "British Overseas Territories citizens who have not ceased to be a British citizen through declaration or renunciation."
    }, {
        status_id: "UKBC_P",
        rule_id: "UK.D.22.3.4.2",
        section_name: "BNA 1981 section 3(5)",
        form_id: "MN1",
        section_description: "Minor born outside UK to a British citizen by descent parent with <270 days absence within last 3 years."
    }, {
        status_id: "UKBC_P",
        rule_id: "UK.D.22.3.19",
        section_name: "BNA 1981 section 3(2)",
        form_id: "MN1",
        section_description: "Minor born outside UK to a British citizen by descent parent with <270 days absence within last 3 years."
    }, {
        status_id: "UKBC_P",
        rule_id: "UK.D.22.3.29",
        section_name: "BNA 1981 section 4G",
        form_id: "UKF",
        section_description: "P born after 1983 who would automatically have become a British citizen if their mother was married to their natural father at birth."
    }, {
        status_id: "UKBC_P",
        rule_id: "UK.D.22.3.28",
        section_name: "BNA 1981 section 4F",
        form_id: "UKF",
        section_description: "Minor who would be eligible under s1(3)/3(2)/3(5)/Sch. 2 p. 4/5 if their mother was married to their natural father at birth."
    }, {
        status_id: "UKBC_P",
        rule_id: "UK.D.22.3.34",
        section_name: "BNA 1981 section 4K",
        form_id: "-",
        section_description: "P who have never been a British citizen who are eligible under s17A/17C/17D/17E/17F/17H."
    }, {
        status_id: "UKBC_P",
        rule_id: "UK.D.22.3.32",
        section_name: "BNA 1981 section 4L",
        form_id: "ARD",
        section_description: "P who would have automatically become a British citizen had historic legislation been fair."
    }, {
        status_id: "UKBC_P",
        rule_id: "UK.D.22.3.33",
        section_name: "BNA 1981 section 4L",
        form_id: "ARD",
        section_description: "P who would have been eligible to become British citizen had historic legislation been fair."
    }, {
        status_id: "UKBC_P",
        rule_id: "UK.D.22.3.18.4",
        section_name: "BNA 1981 section 3(1)",
        form_id: "MN1",
        section_description: "Minor born to a parent who later registered as a British citizen under BNA 1981 s4C/4G/4H/4I/4L and would have automatically become British citizens had they been born after registration."
    }, {
        status_id: "UKBC_P",
        rule_id: "UK.D.22.3.18.6",
        section_name: "BNA 1981 section 3(1)",
        form_id: "MN1",
        section_description: "Minors who were adopted overseas via a UK legally recognised adoption."
    }, {
        status_id: "UKBC_P",
        rule_id: "UK.D.22.3.18.5",
        section_name: "BNA 1981 section 3(1)",
        form_id: "MN1",
        section_description: "Minor born to a parent who later registered as a British citizen under BNA 1981 s4C/4G/4H/4I/4L and would have been entitled to register under s3(2) or 3(5) had they been born after registration."
    }, {
        status_id: "UKBC_P",
        rule_id: "UK.D.22.3.18.9",
        section_name: "BNA 1981 section 3(1)",
        form_id: "MN1",
        section_description: "Minors born in UK, not eligible under other 3(1) rules whose 'future lies in the UK'."
    }, {
        status_id: "UKBC_P",
        rule_id: "UK.D.22.3.18.2",
        section_name: "BNA 1981 section 3(1)",
        form_id: "MN1",
        section_description: "Minor born to a parent who has renounced and subsequently resumed British citizenship, which is citizenship other than by descent."
    }, {
        status_id: "UKBC_P",
        rule_id: "UK.D.22.3.18.10",
        section_name: "BNA 1981 section 3(1)",
        form_id: "MN1",
        section_description: "Minor born outside of UK, not eligible under other 3(1) rules whose 'future lies in the UK'."
    }, {
        status_id: "UKBC_P",
        rule_id: "UK.D.22.3.18.3",
        section_name: "BNA 1981 section 3(1)",
        form_id: "MN1",
        section_description: "Minor born to a parent who has renounced and subsequently resumed British citizenship who would have been entitled to register under s3(2) or 3(5) had their parent not renounced."
    }, {
        status_id: "UKBC_P",
        rule_id: "UK.D.22.3.18.1",
        section_name: "BNA 1981 section 3(1)",
        form_id: "MN1",
        section_description: "Minor whose British parent is employed in community institution service or a service which becomes designated after their birth."
    }, {
        status_id: "UKBC_P",
        rule_id: "UK.D.22.3.5.1",
        section_name: "BNA 1981 section 4(2)",
        form_id: "B(OTA)",
        section_description: "UK National who was absent from UK Territories for <450 days in the last 5 years and <90 days in the last year and is not subject to immigration laws."
    }, {
        status_id: "UKBC_P",
        rule_id: "UK.D.22.6.2",
        section_name: "BNA 1981 section 6(2), Sch 1 para 3",
        form_id: "AN",
        section_description: "Adult P who is married to a British citizen and has been absent from the UK territories for <270 days in the past 3 years."
    }, {
        status_id: "UKBC_P",
        rule_id: "UK.D.22.6.1",
        section_name: "BNA 1981 section 6(1), Sch 1 para 1(1)(a)",
        form_id: "AN",
        section_description: "P with sufficient knowledge of UK life & language who was absent from the UK Territories for <450 days in past 5 years, <90 days in past year."
    }, {
        status_id: "UKBC_P",
        rule_id: "UK.D.22.3.5.2",
        section_name: "BNA 1981 section 4(5)",
        form_id: "B(OTA)",
        section_description: "UK National who has been in Crown service/other services appointed by/on behalf of the Crown."
    }, {
        status_id: "UKBC_P",
        rule_id: "UK.D.22.6.3",
        section_name: "BNA 1981 section 6(1), Sch 1 para 1(3)",
        form_id: "AN",
        section_description: "P with sufficient knowledge of UK life & language and presently serving outside the UK in Crown service."
    }, {
        status_id: "UKBC_P",
        rule_id: "UK.D.22.3.10.1",
        section_name: "BNA 1981 Sch 2 para 3",
        form_id: "S3",
        section_description: "Stateless P aged 18-22 born within UK Territories and lived there for the past 5 years with <450 days of absences during that period."
    }, {
        status_id: "UKBC_P",
        rule_id: "UK.D.22.3.10.2",
        section_name: "BNA 1981 Sch 2 para 3A",
        form_id: "S3",
        section_description: "Stateless minor born within UK Territories and lived there for the past 5 years with <450 days of absences during that period."
    }, {
        status_id: "UKBC_P",
        rule_id: "UK.D.22.3.11",
        section_name: "BNA 1981 Sch 2 para 4",
        form_id: "S2",
        section_description: "Stateless P born outside UK Territories to a British citizen parent, parent lived in UK for last 3 years with <270 days absence."
    }
]
        