import ErrorBoundary from "../../../../misc/ErrorBoundary";
import { observer } from "mobx-react-lite";
import EnquiryCta from "./enquiry/EnquiryCta";


export default observer(function NextStepsSection () {

    return (
        <ErrorBoundary>
            <div className="detail-section">
                <div className="section-header">
                    <div className="header-content" style={{justifyContent: "left", gap: "7px"}}>
                        <h4>Next steps</h4>
                    </div>
                </div>

                <EnquiryCta />
                {/* <ReportCta /> */}
                {/* <AutomationCta /> */}
            </div>
        </ErrorBoundary>
    )
})