import React from "react"
import * as Yup from 'yup'
import Ajax from "../../Ajax"
import { store } from "../../stores/Store"
import { useFormik } from "formik"
import { Button } from "@mui/material"
import Field from "./fields/Field"
import { Alert } from "../.."

export default function QuestionnaireTemplateCreateForm () {

    const formik = useFormik({

        initialValues: {
            Key: "",
            Name: "",
            Json: "",
            AccountUid: ""
        },

        validationSchema: Yup.object({
            Key: Yup.string().required("This field is required"),
            Name: Yup.string().required("This field is required"),
            Json: Yup.string().required("This field is required"),
            AccountUid: Yup.string().required("This field is required")
        }),

        onSubmit: async (values, actions) => {
            await Ajax.Questionnaire.CreateTemplate(values).then(() => {
                store.AlertStore.Close()
                store.QuestionnaireStore.getTemplates()
            }).catch((response) => {
                Alert({message: response.data?.detail ? response.data?.detail : "Couldn't create template"})
            })
        }
    })

    return (
        <form className="form" onSubmit={formik.handleSubmit}>
            <div className="header">
                <h2>Create Template</h2>
            </div>
            <div className="form-fields">
                <div style={{maxWidth: "320px", display: "grid", gap: "10px"}}>
                    <Field.Text 
                        formik
                        name="Key" 
                        label="Template key" 
                        value={formik.values.Key} 
                        onChange={formik.handleChange}
                        warning={formik.submitCount && formik.errors.Key ? formik.errors.Key : false} />
                    <Field.Text 
                        formik
                        name="Name" 
                        label="Template name" 
                        value={formik.values.Name} 
                        onChange={formik.handleChange}
                        warning={formik.submitCount && formik.errors.Name ? formik.errors.Name : false} />
                    <Field.Select
                        formik
                        name="AccountUid" 
                        label="Customer" 
                        helpText="The template will only be available for this customer."
                        value={formik.values.AccountUid} 
                        onChange={formik.handleChange}
                        warning={formik.submitCount && formik.errors.AccountUid ? formik.errors.AccountUid : false}
                        options={store.NodeStore.accounts.filter(account => account.accountType === "customer").map(account => {
                            return {value: account.uid, label: account.label}
                        })} />
                </div>
                <Field.Text 
                    formik
                    name="Json" 
                    label="Template Json" 
                    rows={10}
                    variant="filled"
                    value={formik.values.Json} 
                    onChange={formik.handleChange}
                    warning={formik.submitCount && formik.errors.Json ? formik.errors.Json : false} />
            </div>
            <div className="form-buttons">
                <Button color="primary" className="btn btn-sm" onClick={() => store.AlertStore.Close()} >Cancel</Button>
                    
                <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    className="btn btn-sm">
                    Submit
                </Button>
            </div>
        </form>
    )
}