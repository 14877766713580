import React from "react"
import Ajax from "../../../Ajax"
import { Alert } from "../../.."
import { observer } from "mobx-react-lite"
import { store } from "../../../stores/Store"
import { Button, CircularProgress } from "@mui/material"
import QuestionnaireSelect from "../../views/questionnaire_mgmt/QuestionnaireTable"
import QuestionnaireDetails from "../../views/questionnaire_mgmt/QuestionnaireDetails"

interface LoadQuestionnaireProps {
    caseUid: string;
}

export default observer(function LoadQuestionnaire ({caseUid}: LoadQuestionnaireProps) {

    const [loading, setLoading] = React.useState(false)
    const [questionnaire, setQuestionnaire] = React.useState(null)

    const getQuestionnaire = async (uid) => {
        setLoading(true)
        await Ajax.Questionnaire.Get(uid).then((response) => {
            setQuestionnaire(response.data)
        }).catch(() => {
            Alert({message: "Failed to get questionnaire"})
        })
        setLoading(false)
    }

    const run = async () => {
        store.AlertStore.Close()
        store.QuestionnaireStore.loadQuestionnaire(caseUid, questionnaire.uid)
    }

    if (questionnaire) {

        // var updatedAtDisplay = format(new Date(questionnaire.updatedAt), "yyyy-MM-dd HH:mm")

        return (
            <div className={loading ? "form disabled" : "form"}>
                <h2>Load Questionnaire</h2>
                
                <div className="form-fields">
                    <QuestionnaireDetails questionnaire={questionnaire} />
                </div>

                <div className="form-buttons">
                    <Button className="btn btn-md" onClick={() => setQuestionnaire(null)}>
                        Back
                    </Button>

                    <Button variant="contained" className="btn btn-md" color="success" onClick={() => run()}>
                        {loading ? <CircularProgress /> : "Load this questionnaire"}
                    </Button>
                </div>
            </div>
        )
    }

    return (
        <div className={loading ? "form disabled" : "form"}>
            <h2>Load Questionnaire</h2>
            
            <div className="form-fields">
                <p>Select a questionnaire and AORA will use it to create the person and then run the enquiry.</p>
                
                <div style={{height: "400px"}}>
                    <QuestionnaireSelect handleSelect={(questionnaire) => getQuestionnaire(questionnaire.uid)} />
                </div>
            </div>
        </div>
    )
})