import * as Yup from 'yup'
import { useFormik } from "formik"
import Help from "../../../misc/Help"
import Field from "../fields/Field"
import { format } from 'date-fns'

export default function EnquiryGetOptionsFormSection ({formId, enquiry, submit}) {

    const formik = useFormik({

        initialValues: {
            ruleSystem: enquiry ? enquiry.ruleSystem : "UK",
            interestDate: enquiry ? enquiry.interestDate : format(new Date(), "yyyy-MM-dd")
        },

        validationSchema: Yup.object({
            ruleSystem: Yup.string().required("Please choose a legal system"),
            interestDate: Yup.date()
                .min(new Date("1800-01-01"), `Must be after 1800-01-01`)
                .max(new Date(), `Must be before today`)
                .typeError("Please choose an interest date")
                .required("This field is required")
        }),

        onSubmit: (values, actions) => submit(values)
    })

    return (
        <form id={formId} className="form-fields" onSubmit={formik.handleSubmit}>

            <Help content={<p>Select a legal system and interest date</p>}>
                <p>The enquiry will be evaluated on the given legal system as it is defined on the given interest date.</p>
                <p>Most of the time this will be today's date</p>
            </Help>

            <Field.Select
                formik
                name="ruleSystem"
                label="Legal system"
                disabled={formik.isSubmitting}
                value={formik.values.ruleSystem}
                warning={formik.errors.ruleSystem}
                onChange={(e) => formik.handleChange(e)}
                options={[
                    {label: "United Kingdom", value: "UK"},
                    {label: "Irish Republic", value: "IE"}
                ]}
            />

            <Field.Date
                formik
                name="interestDate"
                label="Interest date"
                disabled={formik.isSubmitting}
                value={formik.values.interestDate}
                warning={formik.errors.interestDate}
                // onChange={(e) => formik.handleChange(e)}
                onChange={(e) => {
                    formik.setFieldValue("interestDate", e.target.value)
                }}
            />

        </form>
    )
}