import React from "react"
import { Button } from "@mui/material"
import { Link } from "react-router-dom"
import { observer } from "mobx-react-lite"
import { Icons } from "../../../misc/Icons"
import { store } from "../../../stores/Store"


export default observer(function BreadCrumb () {

    return (
        <div className={`breadcrumbs ${store.AppStore.sessionState ? "disabled" : ""}`}>

            <Button
                to="/app/main"
                component={Link}
                color="secondary" 
                variant="contained"
                className="btn btn-sm" >
                <Icons.Home style={{color: "white"}} />
                Home
            </Button>

            {store.NodeStore.breadcrumb?.map((nodeRef, i) => (
                <React.Fragment key={i}>
                    <div className="breadcrumb-delimiter">{i > 0 ? <>/</> : null}</div>

                    <Link className="breadcrumb-link link" to={`/app/main/${nodeRef?.uid}`} aria-label={`Back to ${nodeRef?.name ? nodeRef?.name : nodeRef?.uid}`}>
                        {nodeRef?.name ? nodeRef?.name : nodeRef?.uid}
                    </Link>
                </React.Fragment>
            ))}
            
        </div>
    )
})


