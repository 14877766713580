import { observer } from "mobx-react-lite";
import { AlertModal } from "../../../../..";
import { store } from "../../../../../stores/Store";
import { Button, CircularProgress } from "@mui/material";
import CreateAutomationForm from "../../../../form/web_automation/CreateAutomationForm";


interface AutomationOptionsProps {
    onlineForms: any[];
}


export default observer(function AutomationOptions ({onlineForms}: AutomationOptionsProps) {

    var { enquiry } = store.EnquiryStore

    if (enquiry.status !== "DEFINITIVE") return <></>

    var automationInProgress = !!store.AutomationStore.pendingAutomations?.length

    var subjectForms = onlineForms.filter(form => !form.id.includes("CHILD") && !form.id.includes("PARTNER"))
    var dependantForms = onlineForms.filter(form => form.id.includes("CHILD") || form.id.includes("PARTNER"))

    // Available form
    return (
        <div className="form">
            {automationInProgress ? (
                <div style={{display: "flex", gap: "10px", justifyContent: "normal", alignItems: "center"}}>
                    <CircularProgress style={{width: "10px", height: "10px", margin: "0"}} />
                    <small>Automation in progress</small>
                </div>
            ) : (
                <p>We have found {onlineForms?.length > 1 ? `${onlineForms.length} online forms which are` : "an online form which is"} relevant to this enquiry.</p>
            )}

            {subjectForms?.length ? (
                <div>
                    <h4>Main Applicant</h4>
                    {subjectForms.map((onlineForm, i) => <AutomationOption key={i} onlineForm={onlineForm} />)}
                </div>
            ) : null}

            {dependantForms?.length ? (
                <div>
                    <h4>Dependants</h4>
                    {dependantForms.map((onlineForm, i) => <AutomationOption key={i} onlineForm={onlineForm} />)}
                </div>
            ) : null}
        </div>
    )
})


function AutomationOption ({onlineForm}) {

    var automationInProgress = !!store.AutomationStore.pendingAutomations?.length

    if (!onlineForm.automated) {
        return (
            <div style={{width: "600px", display: "grid", gridTemplateColumns: "auto 100px 110px", margin: "10px 10px 10px 20px"}}>
                <div style={{display: "list-item"}}>
                    {onlineForm.name}
                </div>
                <div>
                    <a href={onlineForm.link} target="_blank" rel="noreferrer" className="link">Go to form</a>
                </div>
                <div>
                    Not automated
                </div>
            </div>
        )
    }

    return (
        <div style={{width: "600px", display: "grid", gridTemplateColumns: "auto 100px 110px", margin: "10px 10px 10px 20px"}}>
            <div style={{display: "list-item"}}>
                {onlineForm.name}
            </div>
            <div>
                <a href={onlineForm.link} target="_blank" rel="noreferrer" className="link">Go to form</a>
            </div>
            <div>
                <Button
                    color="success"
                    variant="contained"
                    disabled={automationInProgress}
                    onClick={() => {
                        store.AlertStore.Close()
                        AlertModal({width: "700px", body: <CreateAutomationForm onlineForm={onlineForm} />})
                    }}
                    className="btn btn-sm">
                    Automate
                </Button>
            </div>
        </div>
    )
}