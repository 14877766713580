import { observer } from "mobx-react-lite"
import { Icons } from "../../../../../misc/Icons"
import { Theme } from "../../../../../misc/Theme"
import { Button } from "@mui/material"
import { AlertModal } from "../../../../.."
import AutomationDetail from "./AutomationDetail"
import Help from "../../../../../misc/Help"
import { format } from "date-fns"
import React from "react"
import TruncText from "../../../../../misc/TruncText"
import FormAutomationChart from "../../../../../misc/Charts"


export interface AutomationProps {
    automationNode: any;
}


export default observer(function Automation ({automationNode}: AutomationProps) {

    var outputData = automationNode.serialisedInstance
    outputData = JSON.parse(outputData)

    const pagesCompletePercent = (outputData.current_page / outputData.total_pages) * 100
    const finishingPagePercentage = (outputData.finishing_page_index / outputData.total_pages) * 100

    return (
        <div className="form-automation">

            <Help content={(
                <div className="form-automation-page-bar">
                    <div className="indicator" style={{width: `${pagesCompletePercent}%`}}></div>
                    <div className="page-limit-indicator" style={{marginLeft: `${finishingPagePercentage-2}%`}}></div>
                </div>
            )}>
                <small>Filled {outputData.current_page + 1} out of {outputData.total_pages} pages</small>
            </Help>

            <div className="section-table" style={{marginBottom: "0"}}>
                <div className="section-row" style={{display: "grid", gridTemplateColumns: "22px auto 85px 30px 90px 80px", borderBottom: "0.5px solid rgba(0, 0, 0, 0.089)", padding: "5px"}}>
                    <div className="section-cell">
                        <Icons.Automation color="primary" />
                    </div>
                    <TruncText text={automationNode.label} />
                    <span>
                        <Help content={(
                            format(new Date(automationNode.runDt), "dd/MM/yyyy")
                        )}>
                            <h4>Automated at</h4>
                            {format(new Date(automationNode.runDt), "dd/MM/yyyy HH:mm")}
                        </Help>
                    </span>
                    <FormAutomationChart
                            labels={[]}
                            chartData={[
                                outputData.genuine_value_count,
                                outputData.guessed_value_count,
                                outputData.false_value_count
                            ]}
                            colors={[
                                Theme.palette.success.main,
                                Theme.palette.secondary.main,
                                Theme.palette.error.main,
                            ]}
                        />
                    <AutomationStatus automationNode={automationNode} />
                    <Button 
                        color="success"
                        variant="contained"
                        style={{padding: "4px 4px"}}
                        onClick={() => AlertModal({body: <AutomationDetail automationNode={automationNode} />, width: "1200px"})}>
                        View details
                    </Button>
                </div>
            </div>
        </div>
    )
})




function AutomationStatus ({automationNode}) {

    var outputData = automationNode.serialisedInstance
    outputData = JSON.parse(outputData)

    var label = "Unknown status"
    var toolTip = <small>Something unexpected happened: {outputData.status}</small>
    var color = Theme.palette.error.main

    switch (outputData.status) {
        case "FINISHED":
            label = "Finished"
            var toolTip = <small>The Automation ran successfully.</small>
            color = Theme.palette.success.main
            break

        case "VALIDATION_ERROR":
            label = "Invalid data"
            toolTip = (
                <div>
                    <h4>Invalid data</h4>
                    <small>Some of your input data failed validation in the online form.</small>
                    {outputData?.validation_messages?.length ? (
                        <ul>
                            {outputData?.validation_messages.map((message, i) => (
                                <li key={i}><small>{message}</small></li>
                            ))}
                        </ul>
                    ) : null}
                </div>
            )
            break
        
        case "HOME_OFFICE_ERROR":
            label = "Gov Website Error"
            toolTip = <small>The government website is having some problems at the moment, please try again later."</small>
            break

        case "MAPPING_ERROR":
            label = "Automation Error"
            toolTip = <small>There was an unexpected page which our software could not automate.</small>
            break
    }

    return (
        <Help content={(
            <div className="automation-status">
                <b style={{color: color, fontSize: "14px"}}>{label}</b>
                {/* <small>{format(new Date(automationNode.runDt), "dd/MM/yyyy HH:mm")}</small> */}
            </div>
        )}>
            {toolTip}
        </Help>
    )
}