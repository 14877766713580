import { AlertModal } from "../.."
import Field from "./fields/Field"
import { Button } from "@mui/material"
import AbsenceForm from "./AbsenceForm"
import { Icons } from "../../misc/Icons"
import ServerSideSearchField from "./fields/ServerSideSearchField"


export default function InformationRequestField ({field, formik, certainty, index}) {

    var autoFocus = index === 0 && window.innerWidth > 600 ? true : false

    switch (field.style) {

        case "TEXTBOX":

            // Absence questions are serialised and stored in a single textbox field
            if (field.label === "ABSENCE") {

                if (field.enteredValuesObj.length === 0) return <>Error: No absence block!</>

                var absenceBlock = JSON.parse(field.enteredValuesObj[0])
                const showAbsenceForm = () => AlertModal({body: <AbsenceForm absenceBlock={absenceBlock} />, width: "750px", id: "ABSENCE"})
                showAbsenceForm()

                return <Button variant="contained" className="btn btn-sm" onClick={showAbsenceForm}>Open absence data form</Button>
            }

            var rows = field.maxChars > 200 ? 8 : 1

            return (
                <Field.Text
                    formik
                    label={field.label}
                    autoFocus={autoFocus}
                    name={index.toString()}
                    onChange={formik.handleChange}
                    value={formik.values[index.toString()]}
                    rows={rows}
                    characterCount={rows > 1}
                    placeholder={rows > 1 ? "Enter text here..." : ""}
                    decimalPlaces={field.datatype.includes("Decimal") ? 2 : 0}
                    type={field.datatype.includes("Int32") || field.datatype.includes("Decimal") ? "number" : "text"}
                    disabled={formik.isSubmitting || ["UNKNOWN", "TOFOLLOW"].includes(certainty)}
                    warning={formik.submitCount ? formik.errors[index.toString()] : false} />
            )

        case "DATE":
            return (
                <div>
                    <Field.Date
                        formik
                        label={field.label}
                        autoFocus={autoFocus}
                        name={index.toString()}
                        onChange={(e) => {
                            formik.setFieldValue(index.toString(), e.target.value)
                        }}
                        // onChange={formik.handleChange}
                        minDate={field.minDate}
                        maxDate={field.maxDate}
                        openDateCheckbox={field.hasSecondaryCheckbox ? {label: field.secondaryCheckboxLabel, value: field.secondaryCheckboxValues[0]} : null}
                        value={formik.values[index.toString()]}
                        disabled={formik.isSubmitting || ["UNKNOWN", "TOFOLLOW"].includes(certainty)}
                        warning={formik.submitCount ? formik.errors[index.toString()] : false} />
                </div>
            )

        case "DROPDOWN":
            return (
                <Field.Select
                    formik
                    label={field.label}
                    autoFocus={autoFocus}
                    name={index.toString()}
                    onChange={formik.handleChange}
                    options={field.availableValues}
                    // overrideEnter={formik.handleSubmit}
                    value={formik.values[index.toString()]}
                    disabled={formik.isSubmitting || ["UNKNOWN", "TOFOLLOW"].includes(certainty)}
                    warning={formik.submitCount ? formik.errors[index.toString()] : false} />
            )

        case "LISTBOX":

            const onSearchSubmit = (option) => {
                formik.handleChange({target: {name: index.toString(), value: [option.value]}})
                console.log("Search selection", option.value, option.label)
                var existingOption = field.availableValues.find(o => o.value === option.value)
                if (!existingOption) {
                    field.availableValues.push(option)
                }
            }

            var seachModal = () => AlertModal({
                title: `${field.searchFacility.toLowerCase()} finder`,
                body: <ServerSideSearchField type={field.searchFacility} handleSubmit={onSearchSubmit} returnOption />
            })

            var options = field.availableValues.map((o) => {
                var label = o.label2 ? `${o.label} (${o.label2})` : o.label
                return {label: label, value: o.value}
            })

            return (
                <div style={{display: "grid", gap: "14px"}}>
                    <Field.LargeListSelect
                        formik
                        options={options}
                        label={field.label}
                        autoFocus={autoFocus}
                        name={index.toString()}
                        onChange={formik.handleChange}
                        minValueCount={field.minValueCount}
                        maxValueCount={field.maxValueCount}
                        noneOption={field.minValueCount === 0}
                        value={formik.values[index.toString()]}
                        disabled={formik.isSubmitting || ["UNKNOWN", "TOFOLLOW"].includes(certainty)}
                        warning={formik.submitCount ? formik.errors[index.toString()] : false} />

                    {field.searchFacility ? (
                        <Button className="btn btn-sm" onClick={() => seachModal()}>
                            <Icons.Search />
                            {field.searchFacility?.toLowerCase()} finder
                        </Button>
                    ) : null}
                </div>
            )

        case "RADIO":
            return (
                <Field.Radio
                    formik
                    label={field.label}
                    autoFocus={autoFocus}
                    name={index.toString()}
                    onChange={formik.handleChange}
                    options={field.availableValues}
                    value={formik.values[index.toString()]}
                    disabled={formik.isSubmitting || ["UNKNOWN", "TOFOLLOW"].includes(certainty)}
                    warning={formik.submitCount ? formik.errors[index.toString()] : false} />
            )

        default:
            return <p>Unrecognised field</p>
    }
}