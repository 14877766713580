
import {
    Drawer,
    IconButton
} from '@mui/material';
import { history } from '../..';
import MainMenu from './MainMenu';
import React, { useState } from 'react';
import { Icons } from '../../misc/Icons';
import { store } from '../../stores/Store';
import { observer } from 'mobx-react-lite';
import ReportMgmtTool from '../views/report_mgmt/ReportMgmtTool';
import AccountMgmtTool from '../views/account_mgmt/AccountMgmtTool';
import ProfileMgmtTool from '../views/profile_mgmt/ProfileMgmtTool';
import QuestionnaireView from '../views/questionnaire_mgmt/QuestionnaireTool';


export default observer(function ToolDrawer() {

    const {setOpen, open, setTool, tool} = store.ToolStore

    // React.useEffect(() => handleToolParam(), [])
    history.listen((location, action) => {
        setOpen(false)
        setTool(null)
    })

    document.onkeyup = (e) => {
        e.ctrlKey && e.key === "m" && setOpen(!open)
    }

    return (
        <React.Fragment>
            <IconButton
                onClick={() => {setOpen(true)}}
                color="inherit"
                aria-label="Main menu"
                className="main-menu-toggler">
                <Icons.Menu style={{color: "white"}} />
            </IconButton>
 
            <Drawer
                anchor="left"
                open={open}
                role="menu"
                PaperProps={{
                    style: {
                        boxShadow: "none",
                        backgroundColor: "transparent"
                    }
                }}
                onClose={() => {setOpen(false)}}>
                <div className={`tool-drawer ${tool ? "tool-drawer-open" : null}`}>
                    <MainMenu handleClose={() => setOpen(false)} />
                    
                    <div className="tool-drawer-body">
                        <ToolSwitch />
                    </div>
                </div>
            </Drawer>
        </React.Fragment>
    )
})


const ToolSwitch = observer(() => {
    switch (store.ToolStore.tool) {
        case "account":
            return <AccountMgmtTool />
        case "profile":
            return <ProfileMgmtTool />
        case "report":
            return <ReportMgmtTool />
        case "questionnaire":
            return <QuestionnaireView />
        default:
            return <></>
    }
})