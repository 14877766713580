import Logo from "../../../misc/Logo"
import { observer } from "mobx-react-lite"
import LoginForm from "../../form/LoginForm"
import { store } from "../../../stores/Store"
import ParticleAnimation from "../../../misc/ParticleAnimation"
import { Button } from "@mui/material"
import { Icons } from "../../../misc/Icons"


export default observer(function GuestView () {

    store.AppStore.setPageTitle("AORA - Sign in")

    var subdomain = window.location.hostname.split(".")[0]

    return (
        <div className="view-guest">
            
            <div className="card center-h">
                <LoginForm />
            </div>

            <div className="view-guest-footer">
                <Logo type="block" width="120px" color="white" />
                <small>{subdomain}</small>
            </div>
            
            <div style={{zIndex: -10}}>
                <ParticleAnimation />
            </div>

        </div>
    )
})