import { IconButton } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import React from 'react';
import { Icons } from './Icons';

interface HelpProps {
    content?: string | React.ReactElement<any, any>;
    children?: any;
    open?: boolean;
    arrow?: boolean;
    disabled?: boolean;
    timedClose?: number;
    placement?: 'bottom-end' | 'bottom-start' | 'bottom' | 'left-end' | 'left-start' | 'left' | 'right-end' | 'right-start' | 'right' | 'top-end' | 'top-start' | 'top';
}

export default function Help ({
    content = "",
    children = "",
    open = false,
    arrow = false,
    timedClose = 0,
    placement = "bottom",
    disabled = false
}: HelpProps) {

    const [isOpen, setIsOpen] = React.useState(open)

    React.useEffect(() => {
        if (timedClose) {
            setTimeout(() => {
                setIsOpen(false)
            }, timedClose * 1000)
        }
    })

    return (
        <Tooltip 
            open={isOpen}
            arrow={arrow}
            title={!disabled ? children : null}
            placement={placement}
            onMouseEnter={() => setIsOpen(true)} onMouseLeave={() => setIsOpen(false)}>
            {content ? (
                <span style={{display: "flex", alignItems: "center"}}>{content}</span>
            ) : (
                <IconButton aria-label="help" >
                    <Icons.Info color="info" style={{fontSize: "12px"}} />
                </IconButton>
            )}
        </Tooltip>
    )
}