import {Column, Table, AutoSizer} from 'react-virtualized';
import NodeOptionsMenu from '../../../misc/NodeMenus';
import { Button, LinearProgress } from "@mui/material"
import { store } from '../../../stores/Store';
import { observer } from 'mobx-react-lite';
import { DisplayValue } from '../../..';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { Icons } from '../../../misc/Icons';


export default observer(function ReportMgmtTable () {

    const { loading, reports, filterReports } = store.ReportStore
    const filteredReports = filterReports(reports)
    const rowHeight = 40
    const minWidth = 1090


    return (
        <div className={`rv-table-wrapper rv-table-md`}  style={{minHeight: "400px"}}>
            <AutoSizer>
                {({height, width}) => (
                    <Table
                        width={width < minWidth ? minWidth : width}
                        height={height}
                        headerHeight={rowHeight}
                        rowHeight={rowHeight}
                        rowCount={filteredReports.length}
                        rowGetter={({index}) => filteredReports[index]}
                    >
                        <Column width={140} dataKey="" headerRenderer={() => <ColumnHeader value="subject" />}         cellRenderer={(data) => <Link className='link' to={`/app/main/${data.rowData.case.uid}/${data.rowData.subject.uid}`} >{data.rowData.subject.name}</Link>} />
                        <Column width={120} dataKey="" headerRenderer={() => <ColumnHeader value="case" />}            cellRenderer={(data) => <Link className='link' to={`/app/main/${data.rowData.case.uid}`} >{data.rowData.case.name}</Link>} />
                        <Column width={140} dataKey="" headerRenderer={() => <ColumnHeader value="requestedBy" />}     cellRenderer={(data) => data.rowData.requestedBy.name} />
                        <Column width={120} dataKey="" headerRenderer={() => <ColumnHeader value="account" />}         cellRenderer={(data) => <Link className='link' to={`/app/main/${data.rowData.account.uid}`} >{data.rowData.account.name}</Link>} />
                        <Column width={140} dataKey="" headerRenderer={() => <ColumnHeader value="reportType" />}      cellRenderer={(data) => DisplayValue("report_type", data.rowData.reportType)} />
                        
                        <Column width={140} dataKey="" headerRenderer={() => <ColumnHeader value="statusId" />}        cellRenderer={(data) => DisplayValue("nationality", data.rowData.statusId)} />
                        {/* <Column width={100} dataKey="" headerRenderer={() => <ColumnHeader value="legalSystem" />}     cellRenderer={(data) => DisplayValue("legal_system", data.rowData.legalSystem)} /> */}
                        {/* <Column width={80}  dataKey="" headerRenderer={() => <ColumnHeader value="fileStatus" />}      cellRenderer={(data) => DisplayValue("report_file_status", data.rowData.fileStatus)} /> */}
                        {/* <Column width={50}  dataKey="" headerRenderer={() =m> <ColumnHeader label="Notes" value="userReference" />}         dataKey="userReference" /> */}
                        <Column width={140}  dataKey="" headerRenderer={() => <ColumnHeader value="requestedAt" />}     cellRenderer={(data) => format(new Date(data.rowData.requestedAt), "yyyy-MM-dd")} />
                        {/* <Column width={40} dataKey="reportLanguageId" headerRenderer={() => <ColumnHeader value="reportLanguageId" />} /> */}
                        {/* <Column width={45} dataKey="version" headerRenderer={() => <ColumnHeader value="version" />} /> */}
                        {/* <Column width={45} dataKey="uid" headerRenderer={() => <ColumnHeader value="UID" />} /> */}
                        {/* <Column width={20}  dataKey="" headerRenderer={() => <ColumnHeader value="" />}           cellRenderer={(data) => <ReportOutcome report={data.rowData} />} /> */}
                        <Column width={50} dataKey=""headerRenderer={() => <></>}                                  cellRenderer={(data) => <div style={{marginLeft: "auto"}}><NodeOptionsMenu node={data.rowData} /></div>} />

                    </Table>
                )}
            </AutoSizer>

            {loading ? <LinearProgress color="primary" style={{marginTop: `${rowHeight}px`}} /> : null}
        </div>
    )
})


function ColumnHeader ({value}) {

    var {order, orderedBy, setOrder, setOrderedBy} = store.ReportStore

    return (
        <div>
            <Button className="btn btn-lowercase" color="info" onClick={() => {
                if (orderedBy !== value) {
                    setOrderedBy(value)
                } else {
                    setOrder(order === "asc" ? "desc" : "asc")
                }
            }}>
                {DisplayValue("label", value)}
                
                {orderedBy === value ? (
                    <>
                        {order === "asc" ? <Icons.ArrowDown style={{fontSize: "12px"}} /> : <Icons.ArrowUp style={{fontSize: "12px"}} />}
                    </>
                ) : null}
            </Button>
        </div>
    )
}