const Vanilla = {
    LiveSession: window.__ls,
    Survicate: window._sva,
    SurveyEnquiryFinish: () => {
        console.log("Asking survey")
        window._sva.showSurvey('2673e9871df0b6d6', {forceDisplay: true});
    },
    SurvicateIdentify: (uid, username, accountName) => {
        console.log("Identifying user for Survicate", uid, username, accountName)
        window._sva.setVisitorTraits({
            "uid": uid,
            "name": username,
            "email": username,
            "username": username,
            "account": accountName,
        })
    },
    LiveSessionIdentify: (uid, username, accountName) => {
        console.log("Identifying user for LiveSession", uid, username, accountName)
        window.__ls("identify", {
            // "uid": "uid",
            "name": username,
            // "account": "accountName",
        })
    }
}

export default Vanilla;