export const onlineForms = [
    {
        automated: true,
        name: "Registration application UKM",
        link: "http://visas-immigration.service.gov.uk/product/nationality-ukm",
        rule_id: "UK.D.22.3.21",
        id: "UKM"
    },
    {
        automated: true,
        name: "Naturalisation application AN",
        link: "http://visas-immigration.service.gov.uk/product/nationality-an",
        rule_id: "UK.D.22.6.2",
        id: "AN"
    },
    {
        automated: true,
        name: "Registration application for child MN1",
        link: "http://visas-immigration.service.gov.uk/product/nationality-mn1",
        rule_id: "UK.D.22.3.1",
        id: "MN1"
    },
    {
        automated: true,
        name: "Skilled Worker (ID check)",
        link: "http://www.gov.uk/skilled-worker-visa",
        rule_id: "UK.D.33.1",
        id: "SWV_ID_CHECK"
    },
    {
        automated: true,
        name: "Skilled Worker, Entry clearance",
        link: "http://www.gov.uk/skilled-worker-visa",
        rule_id: "UK.D.33.1",
        id: "SWV_EC"
    },
    {
        automated: true,
        name: "Skilled Worker, FLR",
        link: "http://www.gov.uk/skilled-worker-visa",
        rule_id: "UK.D.33.1",
        id: "SWV_FLR"
    },
    {
        automated: true,
        name: "Skilled Worker Child (ID check)",
        link: "https://apply-to-visit-or-stay-in-the-uk.homeoffice.gov.uk/sort/start/brp_reuse_pbs_dependant_child_in_uk",
        rule_id: "_UK.D.33.1",
        id: "SWV_CHILD_ID_CHECK"
    },
    {
        automated: true,
        name: "Skilled Worker Child, Entry clearance",
        link: "https://apply-to-visit-or-stay-in-the-uk.homeoffice.gov.uk/sort/start/pbs_dependant_child_out_uk",
        rule_id: "_UK.D.33.1",
        id: "SWV_EC_CHILD"
    },
    {
        automated: true,
        name: "Skilled Worker Child, FLR",
        link: "https://apply-to-visit-or-stay-in-the-uk.homeoffice.gov.uk/sort/start/brp_reuse_pbs_dependant_child_in_uk",
        rule_id: "_UK.D.33.1",
        id: "SWV_FLR_CHILD"
    },
    {
        automated: true,
        name: "Skilled Worker Partner (ID check)",
        link: "https://apply-to-visit-or-stay-in-the-uk.homeoffice.gov.uk/sort/start/brp_reuse_pbs_dependant_partner_in_uk",
        rule_id: "_UK.D.33.1",
        id: "SWV_PARTNER_ID_CHECK"
    },
    {
        automated: true,
        name: "Skilled Worker Partner, Entry clearance",
        link: "https://apply-to-visit-or-stay-in-the-uk.homeoffice.gov.uk/sort/start/pbs_dependant_partner_out_uk",
        rule_id: "_UK.D.33.1",
        id: "SWV_EC_PARTNER"
    },
    {
        automated: true,
        name: "Skilled Worker Partner, FLR",
        link: "https://apply-to-visit-or-stay-in-the-uk.homeoffice.gov.uk/sort/start/brp_reuse_pbs_dependant_partner_in_uk",
        rule_id: "_UK.D.33.1",
        id: "SWV_FLR_PARTNER"
    },





    {
        name: "Form UKF",
        link: "http://www.gov.uk/government/publications/form-ukf-guidance",
        rule_id: "UK.D.22.3.30",
        id: "UKF"
    },
    {
        name: "Register as a British citizen - stateless person born before 1983",
        link: "http://www.gov.uk/government/publications/register-as-a-british-citizen-stateless-person-born-before-1983-form-s1",
        rule_id: "UK.D.22.3.12",
        id: "S1"
    },
    {
        name: "Form RS1",
        link: "http://www.gov.uk/government/publications/form-rs1-guidance",
        rule_id: "UK.D.22.3.26",
        id: "RS1"
    },
    {
        name: "",
        link: "",
        rule_id: "UK.D.22.3.27.1",
        id: "EM"
    },
    {
        name: "Form B(OS)",
        link: "http://www.gov.uk/government/publications/form-bos-guidance",
        rule_id: "UK.D.22.3.20",
        id: "B(OS)"
    },
    {
        name: "Application for registration of a child under 18 as a British citizen",
        link: "http://www.gov.uk/government/publications/form-mn1-guidance",
        rule_id: "UK.D.22.3.1",
        id: "MN1"
    },
    {
        name: "Form T",
        link: "http://www.gov.uk/government/publications/form-t-guidance",
        rule_id: "UK.D.22.3.3",
        id: "T"
    },
    {
        name: "Form B(OTA)",
        link: "http://www.gov.uk/government/publications/register-as-a-british-citizen-form-bota",
        rule_id: "UK.D.22.3.6",
        id: "B(OTA)"
    },
    {
        name: "Form ARD",
        link: "http://www.gov.uk/government/publications/application-for-registration-as-a-british-citizen-form-ard",
        rule_id: "UK.D.22.3.32",
        id: "ARD"
    },
    {
        name: "Form AN",
        link: "http://www.gov.uk/government/publications/form-an-guidance",
        rule_id: "UK.D.22.6.2",
        id: "AN"
    },
    {
        name: "UK-born stateless person - Form S3",
        link: "http://www.gov.uk/government/publications/register-as-a-british-citizen-uk-born-stateless-person-form-s3",
        rule_id: "UK.D.22.3.10.1",
        id: "S3"
    },
    {
        name: "Stateless person - Form S2",
        link: "http://www.gov.uk/government/publications/register-as-a-british-citizen-stateless-person-born-outside-the-uk-or-british-overseas-territories-form-s2",
        rule_id: "UK.D.22.3.11",
        id: "S2"
    }
]