import EntityCta from "./EntityCta"
import { observer } from "mobx-react-lite"
import { store } from "../../../../stores/Store"
import EntityDetailSection from "./EntityDetailSection"
import { flattenNode } from "../../../../misc/nodeFlattener"
import Panel from "../../../layout/Panel"
import PanelLabel from "../../../layout/PanelLabel"
import PanelHeader from "../../../layout/PanelHeader"
import PanelBody from "../../../layout/PanelBody"
import { DetailPanelProps } from "./DetailPanel"


export default observer(function EntityDetail ({index}: DetailPanelProps) {

    const { leafNode, loadingLeafNode, setLeafNode } = store.NodeStore

    const flattenedNode = flattenNode(leafNode)

    return (
        <Panel index={index} id="DETAIL" className="detail" width="100%" loadingContent={loadingLeafNode}>

            <PanelLabel title="Details" />

            <PanelHeader node={leafNode} closeable onClose={() => setLeafNode(null)}>
                <EntityCta entity={leafNode} />

                {leafNode.role ? <small style={{color: "grey"}}>The {leafNode.role}</small> : null}
            </PanelHeader>

            <PanelBody>
                <div className="panel-columns">
                    <div className="panel-column">
                        {flattenedNode.sections.filter((s, i) => (i < (flattenedNode.sections.length/2)) || s.uid.includes("ATTRS")) // Iterate every other section and always show attrs
                            .map((section: any, section_index: number) => <EntityDetailSection entityUid={leafNode.uid} key={section_index} section={section} />)}
                    </div>
                    
                    <div className="panel-column">
                        {flattenedNode.sections.filter((s, i) => !(i < (flattenedNode.sections.length/2)) && !s.uid.includes("ATTRS")) // Iterate every other section
                            .map((section: any, section_index: number) => <EntityDetailSection entityUid={leafNode.uid} key={section_index} section={section} />)}
                    </div>
                </div>
            </PanelBody>

        </Panel>
    )
})