import { Button } from "@mui/material";
import React from "react";
import { DisplayDictionary } from "../../..";
import { Icons } from "../../../misc/Icons";
import { store } from "../../../stores/Store";


export default function DemoLanguage () {
    return (
        <div className="panel-columns">
            <div className="settings-section">
                {Object.keys(store.AppStore.displayDictionaries).map((setName, i) => <ListSet key={i} setName={setName} />)}
            </div>
        </div>
    )
}

function ListSet ({setName}) {

    const [minimised, setMinimised] = React.useState(true)

    return (
        <>
            <div style={{display: "flex"}}>                
                <Button className="btn btn-lowercase" onClick={() => setMinimised(!minimised)}>
                    <Icons.ChevronRight style={{transform: minimised ? "rotate(0deg)" : "rotate(90deg)" }} />
                    {setName}
                </Button>
            </div>
            
            {!minimised ? (
                <table style={{background: "#ffd2d2"}}>
                    <thead>
                        <tr><td>Key</td><td>Value</td></tr>
                    </thead>
                    <tbody>
                        {Object.keys(DisplayDictionary(setName)).map((key) => (
                            <tr>
                                <td style={{width: "160px", paddingLeft: "5px !important"}}>{key}</td>
                                <td>{DisplayDictionary(setName)[key]}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : null}
        </>
    )
}