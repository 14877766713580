import React from "react"
import Ajax from "../../../Ajax"
import Field from "../fields/Field"
import { Button } from "@mui/material"
import { store } from "../../../stores/Store"
import { Icons } from "../../../misc/Icons"


export default function DeleteForm ({node, onSuccess = () => {}}) {

    const [labelConfirmation, setLabelConfirmation] = React.useState("")
    const [successors, setSuccessors] = React.useState(null)
    const [success, setSuccess] = React.useState(null)
    const [submitting, setSubmitting] = React.useState(null)
    const [errorMessage, setErrorMessage] = React.useState<string | false>(false)
    const typeName = store.NodeStore.getDisplayType(node)
    const displayName = store.NodeStore.getDisplayName(node)
    const showSuccessors = node.uid.includes("FOLDER")

    React.useEffect(() => {
        if (showSuccessors) {
            Ajax.Node.GetSuccessors(node.uid).then((response) => {
                setSuccessors(response.data)
            })
        }
    }, [])

    const deleteNode = async () => {

        if (displayName !== labelConfirmation) {
            return setErrorMessage(`The confirmation text does not match the ${typeName}'s name`)
        }

        setSubmitting(true)

        await Ajax.Node.Delete(node.uid).then(() => {
            setSuccess(true)
            onSuccess()
            var nodeInBreadcrumb = store.NodeStore.breadcrumb.find(nodeRef => nodeRef.uid === node.uid)
            if (nodeInBreadcrumb) {
                var breadcrumb = store.NodeStore.breadcrumb.filter(nodeRef => nodeRef.uid !== node.uid)
                store.NodeStore.navigateNode(breadcrumb[breadcrumb.length-1].uid)
            } else {
                store.NodeStore.refreshFolderNode()
            }
        }).catch((response) => {
            var message = response.data?.detail ? response.data?.detail : "Something went wrong"
            setErrorMessage(message)
        })

        setSubmitting(false)
    }

    if (success) return <h4>This {typeName} was successfully deleted</h4>

    return (
        <form className="form" onSubmit={(e) => {
            e.preventDefault()
            deleteNode()
        }}>
            <div className="form-header">
                <h2>Delete {typeName}</h2>
            </div>

            <div className="form-fields">
                <div>
                    {showSuccessors && successors?.length ? (
                        <>
                            <p>Deleting this {typeName} will also delete the everything held within:</p>

                            <div style={{margin: "18px"}}>
                                {successors?.map((successor, i) => (
                                    <div style={{display: "flex", gap: "6px", alignItems: "center", margin: "6px 0 6px"}}> 
                                        {successor.uid.includes("FOLDER") 
                                            ? <Icons.Folder style={{fontSize: "16px", color: "grey"}} />
                                            : <Icons.Case style={{fontSize: "16px", color: "grey"}} />}
                                        
                                        {successor.name} {successor.uid.includes("CASE") ? "(including all people and enquiries)" : null}
                                    </div>
                                ))}
                            </div>
                        </>
                    ) : null}

                    <p>
                        Please type <b>"{displayName}"</b> below to confirm that you want 
                        to <b>permanently delete</b> this {typeName} and all of the 
                        data and entities it contains.
                    </p>
                </div>

                <Field.Text
                    formik
                    label="Delete confirmation"
                    value={labelConfirmation}
                    onChange={(e) => setLabelConfirmation(e.target.value)}
                    warning={errorMessage} />
            </div>

            <div className="form-buttons">
                <Button
                    className="btn btn-sm"
                    variant="contained"
                    color="success"
                    onClick={() => store.AlertStore.Close()}>
                    Cancel
                </Button>
                <Button
                    className="btn btn-sm"
                    variant="contained"
                    color="error"
                    type="submit"
                    disabled={displayName !== labelConfirmation || submitting}>
                    Delete
                </Button>
            </div>
        </form>
    )
}