import Logo from '../../misc/Logo'
import ToolDrawer from './ToolDrawer'
import { Link } from 'react-router-dom'
import { Icons } from '../../misc/Icons'
import { observer } from 'mobx-react-lite'
import { store } from '../../stores/Store'
import { Button, Toolbar } from '@mui/material'


export default observer(function PrimarySearchAppBar() {

    return (
        <Toolbar className="nav-header">
            {store.AppStore.isLoggedIn && <ToolDrawer />}

            <Button 
                component={Link} 
                aria-label='Home' 
                color="info" 
                className="logo-button" 
                to="/app/main">
                <Logo height="20px" color="white" type="block" />
            </Button>

            <a target="_blank" rel="noreferrer" href="https://www.aoralaw.com/resources.php" style={{marginLeft: "auto"}}>
                <Button className="btn btn-sm" variant="contained" color="info">
                    <Icons.Support />
                    <h4 style={{color: "#444444"}}>Support</h4>
                </Button>
            </a>
        </Toolbar>
    )
})