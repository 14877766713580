import React from "react"
import Ajax from "../../../Ajax"
import { Alert } from "../../.."
import { Button } from "@mui/material"
import { observer } from "mobx-react-lite"
import { store } from "../../../stores/Store"
import QuestionnaireSelectField from "../questionnaire/QuestionnaireSelectField"


export default observer(function EnquiryResume () {

    const [loading, setLoading] = React.useState(false)
    const [questionnaireUid, setQuestionnaireUid] = React.useState(false)

    const submit = async () => {
        setLoading(true)
        
        await Ajax.Enquiry.Rerun(store.EnquiryStore.enquiry.uid, questionnaireUid).then(() => {
            store.AlertStore.Close()
        }).catch((response) => {
            Alert({message: response.data?.detail ? response.data.detail : "Couldn't resume enquiry"})
        })
        
        setLoading(false)
    }

    var highlightedOptions = store.EnquiryStore.enquiry?.origins
        ? store.EnquiryStore.enquiry.origins.map((origin) => origin.originUid)
        : []

    return (
        <div className="form">
            <div className="form-fields">
                <QuestionnaireSelectField 
                    value={questionnaireUid} 
                    highlightedOptions={highlightedOptions}
                    onChange={(questionnaireUid) => setQuestionnaireUid(questionnaireUid)} />
            </div>

            <div className="form-buttons">
                <Button 
                    className="btn btn-sm" 
                    onClick={() => store.AlertStore.Close()}>
                    Cancel
                </Button>

                <Button 
                    className="btn btn-sm"
                    color="success"
                    variant="contained"
                    disabled={loading}
                    onClick={() => submit()}>
                    {questionnaireUid ? "Run using this questionnaire" : "Run without questionnaire"}
                </Button>
            </div>
        </div>
    )
})