import * as Yup from 'yup'
import { useFormik } from "formik"
import Field from "../fields/Field"
import { DisplayValue } from '../../..'
import { FormSectionProps } from '../../../models/Interfaces'


export default function EnquiryStatusesFormSection ({formId, onSubmit, section}: FormSectionProps) {

    var formik = useFormik({

        initialValues: { statuses: [] },

        validationSchema: Yup.object({
            statuses: Yup.array()
                .min(1, `You must select a type of enquiry`)
        }),

        onSubmit: (values) => {
            var newSection = {...section}
            newSection.childrenObjects = section.childrenObjects
            // .filter(status => status.code.slice(-2) !== "_P") // Don't send potential statuses, these can only be set by the backend
            .map(status => {
                status.selected = values.statuses.includes(status.uid)
                return status
            })
            onSubmit(newSection)
        }
    })

    // var swv = section.childrenObjects.find(s => s.code === "UKLLR_SW_P")

    // if (swv) {
    //     section.childrenObjects = [...section.childrenObjects.filter(s => s.code === "UKLLR_SW_P")]
    // }

    // Transform flat list of statuses into a tree structured by type
    var nationalityOptionsNested = []
    var categoryOrder = ["SW", "CITIZEN", "PROTÉGÉ", "PR_STATUS", "TR_STATUS"]

    // Add each status to tree
    categoryOrder.forEach((category => {

        var statuses = section.childrenObjects.filter(nat => category.includes(nat.category))

        // if (category === "CITIZEN.PROTÉGÉ") {
        //     category = "CITIZEN"
        //     // statuses.forEach(status => {
        //     //     status.category = "CITIZEN"
        //     // })
        // }

        // if (category === "PR_STATUS") {
        //     statuses = statuses.reverse()
        // }

        if (statuses?.length) {
            nationalityOptionsNested.push({
                label: DisplayValue("status_tree_category", category),
                value: category,
                children: statuses.map(status => {
                    return {
                        value: status.uid,
                        label: status.label,
                        // disabled: disabled
                    }
                })
            })
        }
    }))

    return (
        <form id={formId} className="form-fields" onSubmit={formik.handleSubmit}>
            <p>Select the legal statuses you want to investigate</p>

            <div style={{maxHeight: "300px", overflow: "auto"}}>
                {/* <Field.CheckboxSelect
                    formik
                    name={"statuses"}
                    options={nationalityOptionsNested}
                    onChange={formik.handleChange}
                    value={formik.values.statuses}
                    warning={null} /> */}
                
                {nationalityOptionsNested.map((statusGroup, i) => (
                    <div>
                        <h4>{statusGroup.label}</h4>
                        <div style={{margin: "10px", display: "grid", gap: "5px"}}>
                            {statusGroup.children.map((option, o) => (
                                <Field.Checkbox 
                                    formik
                                    name={option.value} 
                                    label={option.label} 
                                    value={formik.values.statuses.includes(option.value)}
                                    onChange={(e) => {
                                        formik.setFieldValue("statuses", [option.value])
                                    }} />
                            ))}
                        </div>
                    </div>
                ))}
            </div>

            {formik.errors.statuses
                ? <div className="form-message warning">{formik.errors.statuses}</div>
                : null}
        </form>
    )
}



