import {Column, Table, AutoSizer} from 'react-virtualized';
import { AlertModal, DisplayValue } from '../../..';
import { store } from '../../../stores/Store';
import { Button, LinearProgress } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { format, isValid } from 'date-fns';
import React from 'react';
import ViewQuestionnaire from './ViewQuestionnaire';
import { Icons } from '../../../misc/Icons';


interface QuestionnaireTableProps {
    width?: string;
    height?: string;
    handleSelect?: Function;
    size?: "sm" | "md";
    highlightedOptions?: string[];
}


export default observer(function QuestionnaireTable ({
    handleSelect,
    height = "400px",
    size = "md",
    highlightedOptions = []
}: QuestionnaireTableProps) {

    const { loading, questionnaires, filterQuestionnaires, getQuestionnaires } = store.QuestionnaireStore
    const minWidth = 990
    var rowHeight = size === "md" ? 40 : 30
    var list = filterQuestionnaires(questionnaires)

    React.useEffect(() => {
        getQuestionnaires()
    }, [])

    const formatDate = (dateString, placeholder = "") => {
        if (!dateString) return <span className="font-grey">{placeholder}</span>
        var date = new Date(dateString)
        return isValid(date) ? format(date, "dd/MM/yyyy H:mm") : <span className="font-grey">{placeholder}</span>
    }

    const CellWrapper = ({children, uid}) => {

        var highlight = highlightedOptions.includes(uid)

        return (
            <div style={{
                fontWeight: highlight ? 600 : 400,
            }}>
                {children}
            </div>
        )
    }

    return (
        <div className={`rv-table-wrapper rv-table-${size}`} style={{minHeight: height}}>
            <AutoSizer>
                {({height, width}) => (
                    <Table
                        width={width < minWidth ? minWidth : width}
                        height={height}
                        headerHeight={rowHeight}
                        onRowClick={({event, index, rowData}) => {
                            if (handleSelect) {
                                handleSelect(rowData)
                            } else {
                                AlertModal({width: "700px", body: <ViewQuestionnaire uid={rowData.uid} />})
                            }
                        }}
                        rowHeight={rowHeight}
                        rowCount={list?.length}
                        rowGetter={({index}) => list[index]}
                    >
                        
                        <Column 
                            dataKey=""
                            width={150}  
                            headerRenderer={() => <ColumnHeader value="displayName" />}          
                            cellRenderer={({rowData}) => (
                                <CellWrapper uid={rowData.uid}>
                                    {rowData.displayName}
                                </CellWrapper>
                            )} />
                        
                        <Column 
                            dataKey=""
                            width={150}  
                            headerRenderer={() => <ColumnHeader value="template" />}             
                            cellRenderer={({rowData}) => (
                                <CellWrapper uid={rowData.uid}>
                                    {rowData.template.name}
                                </CellWrapper>
                            )} />
                        
                        <Column 
                            dataKey=""
                            width={150}  
                            headerRenderer={() => <ColumnHeader value="respondent" />}           
                            cellRenderer={({rowData}) => (
                                <CellWrapper uid={rowData.uid}>
                                    {rowData.respondent?.respondentEmail}
                                </CellWrapper>
                            )} />
                        
                        <Column 
                            dataKey=""
                            width={150}  
                            headerRenderer={() => <ColumnHeader value="notificationEmail" />}    
                            cellRenderer={({rowData}) => (
                                <CellWrapper uid={rowData.uid}>
                                    {rowData.notificationEmail}
                                </CellWrapper>
                            )} />
                        
                        <Column 
                            dataKey=""
                            width={140}  
                            headerRenderer={() => <ColumnHeader value="createdAt" />}            
                            cellRenderer={({rowData}) => (
                                <CellWrapper uid={rowData.uid}>
                                    {formatDate(rowData.createdAt)}
                                </CellWrapper>
                            )} />
                        
                        <Column 
                            dataKey=""
                            width={140}  
                            headerRenderer={() => <ColumnHeader value="updatedAt" />}            
                            cellRenderer={({rowData}) => (
                                <CellWrapper uid={rowData.uid}>
                                    {formatDate(rowData.updatedAt, "Not started")}
                                </CellWrapper>
                            )} />
                        
                        <Column dataKey="" width={35}   headerRenderer={(rowData) => <CellWrapper uid={rowData.uid}><></></CellWrapper>}                                          cellRenderer={(data) => <div style={{marginLeft: "auto"}}></div>} />
                    </Table>
                )}
            </AutoSizer>

            {loading ? <LinearProgress color="primary" style={{marginTop: `${rowHeight}px`}} /> : null}
        </div>
    )
})



function ColumnHeader ({value}) {

    var {order, orderedBy, setOrder, setOrderedBy} = store.QuestionnaireStore

    return (
        <div>
            <Button className="btn btn-lowercase" color="info" onClick={() => {
                if (orderedBy !== value) {
                    setOrderedBy(value)
                } else {
                    setOrder(order === "asc" ? "desc" : "asc")
                }
            }}>
                {DisplayValue("dict_key", value)} 
                
                {orderedBy === value ? (
                    <>
                        {order === "asc" ? <Icons.ArrowDown style={{fontSize: "12px"}} /> : <Icons.ArrowUp style={{fontSize: "12px"}} />}
                    </>
                ) : null}
            </Button>
        </div>
    )
}