import React from 'react';
import Ajax from '../../../Ajax';
import Panel from '../../layout/Panel';
import { Button } from '@mui/material';
import { observer } from 'mobx-react-lite'
import { Icons } from '../../../misc/Icons';
import { Alert, AlertModal } from '../../..';
import { store } from '../../../stores/Store';
import PanelBody from '../../layout/PanelBody';
import PanelLabel from '../../layout/PanelLabel';
import PanelHeader from '../../layout/PanelHeader';
import {SystemInformation} from '../../modals/SystemInformation';
import InformationRequestForm from '../../form/InfomationRequestForm';
import { Theme } from '../../../misc/Theme';


export default observer(function QuestionPanel () {

    const {AppStore, QuestionStore} = store
    const [cancelling, setCancelling] = React.useState(false)

    var stateLowerCase = AppStore.sessionState?.toLowerCase()
    var title = stateLowerCase
        ? `${AppStore.stateAction} ${AppStore.stateSubject}`.split("_")[0].toLowerCase().replace("node", "") // .replaceAll("_", " ").toLowerCase().replace("node", "")
        : QuestionStore.initialisationForm?.toLowerCase().replace("_", " ").replace("node", "")

    const hidden = !QuestionStore.showQuestionPanel

    const cancel = async () => {
        setCancelling(true)
        QuestionStore.cancel()
        setCancelling(false)
    }

    return (
        <Panel
            id="QUESTION"
            index={1}
            minimisable
            className="panel-question"
            hidden={hidden}
            loadingContent={hidden}
            width={hidden ? "0px" : "320px"} >

            <PanelLabel title="Information request" />

            <PanelHeader>
                <div style={{width: "100%", height: "100%", display: "grid", gap: "7px"}}>
                    <small className="panel-header-node-type"><Icons.Info />Information request</small>
                    
                    <div style={{width: "100%", display: "flex", justifyContent: "space-between", alignItems: "end"}}>
                        <h3 className="trunc-ellipsis" style={{textTransform: "capitalize"}}>{title}</h3>
                        <Button
                            color="info"
                            className="btn btn-sm btn-icon btn-lowercase"
                            aria-label="Pause enquiry"
                            disabled={cancelling}
                            onClick={() => cancel()}>
                            {stateLowerCase?.includes("running") 
                                ? <><Icons.Pause />Pause</> 
                                : <><Icons.X />Cancel</>}
                        </Button>
                    </div>
                </div>
            </PanelHeader>

            <PanelBody>
                <div style={{overflow: "auto"}}>
                    <InformationRequestForm />

                    {store.ConnectionStore.connectionState !== "Connected" ? (
                        <small className="link" style={{marginTop: "15px"}} onClick={() => AlertModal({body: <SystemInformation />, size: "lg"})}>
                            You have a faulty connection and may not receive questions
                        </small>
                    ) : null}
                </div>
            </PanelBody>

        </Panel>
    )
})


