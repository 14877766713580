import PlaceholderComponent from "../../misc/PlaceholderComponent"
import { PanelChildProps } from "./Panel"

export default function PanelBody (props: PanelChildProps) {
    
    const generateClasses = () => {
        var classes = "panel-body"
        classes = props.className ? classes.concat(" "+props.className) : classes
        classes = props.hidden || props.minimised ? classes.concat(" panel-child-hidden") : classes
        return classes
    }

    return (
        <div className={generateClasses()} aria-hidden={props.minimised} tabIndex={props.minimised ? -1 : null} >
            {!props.loadingContent ? (
                props.children
            ) : (
                <div className="panel-columns">
                    <div><PlaceholderComponent /></div>
                    <div><PlaceholderComponent /></div>
                </div>
            )}
        </div>
    )
}