import { observer } from "mobx-react-lite"
import { history } from "../../.."
import { store } from "../../../stores/Store"
import { useEffect } from "react"
import ReportMgmtTable from "./ReportMgmtTable"
import { Button, IconButton } from "@mui/material"
import { Icons } from "../../../misc/Icons"
import PanelHeader from "../../layout/PanelHeader"
import InlineMenu from "../../../misc/InlineMenu"
import ReportFilterForm from "./ReportFilterForm"
import PanelBody from "../../layout/PanelBody"
import Field from "../../form/fields/Field"


export default observer(function ReportMgmtTool() {
    
    const { getReports, searchString, setSearchString } = store.ReportStore
    const { setTool } = store.ToolStore

    useEffect(() => {
        if (!store.ReportStore.reports?.length) {
            getReports()
        }
    }, [])

    return (
        <div className="panel panel-dark">
            <PanelHeader>
                <div className="header">
                    <h2>Report Management</h2>

                    <div style={{display: "flex", gap: "5px", alignItems: "center"}}>
                        <div className="form-search">
                            <input 
                                autoFocus 
                                value={searchString}
                                placeholder="Search"
                                spellCheck="false"
                                onChange={(e) => {
                                    setSearchString(e.target.value)
                                }} />
                        </div>
                        <Button
                            color="info"
                            className="btn btn-sm"
                            onClick={() => getReports()}>
                            <Icons.Refresh /> Refresh
                        </Button>
                    </div>
                </div>

                <IconButton style={{marginLeft: "auto"}} size="small" onClick={() => setTool(null)}><Icons.X /></IconButton>
            </PanelHeader>
            
            <PanelBody>
                <ReportMgmtTable />
            </PanelBody>
        </div>
    )
})