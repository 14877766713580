import { Button, LinearProgress } from "@mui/material";
import QuestionnaireDetails from "./QuestionnaireDetails";
import Ajax from "../../../Ajax";
import React from "react";
import { store } from "../../../stores/Store";
import { Alert, AlertModal } from "../../..";

export default function ViewQuestionnaire ({uid}) {

    const [loading, setLoading] = React.useState(false)
    const [error, setError] = React.useState(null)
    const [questionnaire, setQuestionnaire] = React.useState(null)

    const getQuestionnaire = async () => {
        await Ajax.Questionnaire.Get(uid).then((response) => {
            setQuestionnaire(response.data)
        }).catch((response) => {
            Alert({message: "Couldn't get questionnaire"})
            store.AlertStore.Close()
        })
    }

    const toggleLock = async () => {

        var updatedQuestionnaire = {
            uid: questionnaire.uid,
            locked: !questionnaire.locked
        }

        await Ajax.Questionnaire.Update(updatedQuestionnaire).then(async () => {
            await getQuestionnaire()
        }).catch((response) => {
            Alert({message: response.data?.detail ? response.data.detail : "Couldn't update questionnaire"})
        })
    }
    
    React.useEffect(() => {
        setLoading(true)
        getQuestionnaire()
        setLoading(false)
    }, [])

    if (loading || !questionnaire) return <LinearProgress />

    return (
        <div className="form">
            <div className="form-header">
                <h2>Questionnaire details</h2>
            </div>
            
            <QuestionnaireDetails questionnaire={questionnaire} editable={true} />

            <div className="form-buttons">
                <Delete questionnaire={questionnaire} />
                <LockToggler questionnaire={questionnaire} onClick={() => toggleLock()} />
            </div>
        </div>
    )
}




function Delete ({questionnaire}) {

    const [submitting, setSubmitting] = React.useState(false)

    const confirmDelete = () => {
        AlertModal({body: (
            <div className="form">
                <div className="form-fields">
                    <h4>Are you sure you want to permanently delete this questionnaire?</h4>
                </div>
                <div className="form-buttons">
                    <Button 
                        className="btn btn-sm" 
                        variant="contained"
                        onClick={() => store.AlertStore.Close()}>
                        Cancel
                    </Button>
                    <Button  
                        className="btn btn-sm" 
                        variant="contained" 
                        color="error" 
                        onClick={async () => {
                            store.AlertStore.Close()
                            setSubmitting(true)
                            await Ajax.Questionnaire.Delete(questionnaire.uid).then(() => {
                                store.QuestionnaireStore.getQuestionnaires()
                                store.AlertStore.Close()
                            }).catch((response) => {
                                Alert({message: response.data?.detail ? response.data.detail : "Couldn't delete questionnaire"})
                            })
                            setSubmitting(false)
                    }}>
                        Confirm and delete
                    </Button>
                </div>
            </div>
        )})
    }

    return (
        <div>
            <Button 
                disabled={submitting} 
                className="btn btn-sm" 
                variant="contained" 
                color="error" 
                onClick={async (e) => {
                    confirmDelete()
                }}>
                Delete
            </Button>
        </div>
    )
}


function LockToggler ({questionnaire, onClick}) {

    const [submitting, setSubmitting] = React.useState(false)

    return (
        <Button
            className="btn btn-sm"
            variant="contained" 
            disabled={submitting}
            color="primary"
            onClick={async (e) => {
                setSubmitting(true)
                await onClick()
                setSubmitting(false)
            }}>
            {questionnaire.locked ? "Unlock" : "Lock"}
        </Button>
    )
}