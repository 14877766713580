import { AlertModal } from "../../.."
import { observer } from "mobx-react-lite"
import { store } from "../../../stores/Store"
import TruncText from "../../../misc/TruncText"
import InlineMenu from "../../../misc/InlineMenu"
import IsAllowed from "../../../models/Permissions"
import DeleteForm from "../../form/case/DeleteForm"
import { Button, CircularProgress } from "@mui/material"
import UpdatePasswordForm from "../../form/admin/UserPasswordUpdateForm"
import { UserCreateForm, UserUpdateForm } from "../../form/admin/UserForms"


export default observer(function AccountMgmtChildren () {

    const { sessionState } = store.AppStore
    const { account, users, loading, getAccount } = store.ToolStore

    if (!account || loading) return <CircularProgress color="info" />

    return (
        <div className="tool-section">
            <h3>Users</h3>
            <div className="tool-table">
                <p style={{display: "grid", gridTemplateColumns: "35px auto 100px 140px 140px"}}>
                    <span></span>
                    <span style={{minWidth: "100px"}}><b>Name</b></span>
                    <span><b>UID</b></span>
                    {/* <span><b>Open enquiries</b></span> */}
                    {/* <span><b>Closed enquiries</b></span> */}
                </p>
                {users.map((user, i) => <UserDetail key={i} user={user} />)}
            </div>


            {IsAllowed("User", "CREATE") ? (
                <Button
                    color="info"
                    variant="contained"
                    className="btn btn-sm"
                    disabled={!!sessionState}
                    onClick={() => {
                        AlertModal({body: <UserCreateForm account={account} onSuccess={() => {
                            getAccount(account.uid)
                            store.AlertStore.Close()
                        }} />})
                    }}>
                    Create new user
                </Button>
            ) : null}
        </div>
    )
})

function UserDetail ({user}) {
    return (
        <p style={{display: "grid", gridTemplateColumns: "35px auto 100px 140px 140px"}}>
            <InlineMenu
                disabled={!!store.AppStore.sessionState}
                title={store.NodeStore.getDisplayType(user) + " options"}
                menuItems={[
                    {
                        label: "Update password",
                        action: () => AlertModal({body: <UpdatePasswordForm user={user} />, size: "xs"})
                    },
                    {
                        label: "Edit user",
                        action: () => AlertModal({body: <UserUpdateForm requestFullUser user={user} onSuccess={() => {
                            store.ToolStore.getAccount()
                            store.AlertStore.Close()
                        }} />}),
                        hidden: !IsAllowed(user, "UPDATE")
                    },
                    {
                        label: "Delete user",
                        action: () => AlertModal({body: <DeleteForm node={user} onSuccess={() => {
                            store.ToolStore.getAccount()
                            store.AlertStore.Close()
                        }} />}),
                        hidden: !IsAllowed(user, "DELETE")
                    },
            ]} />
            <TruncText text={user.label} style={{minWidth: "100px"}} />
            <TruncText text={user.uid} />
            {/* <TruncText text={user.openEnquiries} /> */}
            {/* <TruncText text={user.closedEnquiries} /> */}
        </p>
    )
}