import React from "react"
import Ajax from "../../../Ajax"
import QuestionnaireTable from "../../views/questionnaire_mgmt/QuestionnaireTable"
import { Alert } from "../../.."
import QuestionnaireDetails from "../../views/questionnaire_mgmt/QuestionnaireDetails"
import { Button } from "@mui/material"

export default function QuestionnaireSelectField ({
    value,
    highlightedOptions = [],
    onChange = (questionnaireId) => {}
}) {

    const [loading, setLoading] = React.useState(false)
    const [questionnaire, setQuestionnaire] = React.useState(null)

    React.useEffect(() => {
        if (value && !questionnaire) {
            getQuestionnaire(value)
        }
    })

    const getQuestionnaire = async (uid) => {
        setLoading(true)
        
        await Ajax.Questionnaire.Get(uid).then((response) => {
            setQuestionnaire(response.data)
            onChange(uid)
        }).catch(() => {
            Alert({message: "Failed to get questionnaire"})
        })

        setLoading(false)
    }

    if (questionnaire) {
        return (
            <div className={loading ? "disabled" : ""}>
                <QuestionnaireDetails 
                    height="260px"
                    questionnaire={questionnaire} />
                
                <Button 
                    className="btn btn-xs" 
                    variant="contained" 
                    color="error" 
                    onClick={() => {
                        setQuestionnaire(null)
                        onChange(null)
                    }}>
                    Remove selection
                </Button>
            </div>
        )
    }

    return (
        <div className={loading ? "disabled" : ""}>
            <p>Select a questionnaire to feed data into the enquiry (optional).</p>
            
            <QuestionnaireTable 
                size="sm" 
                width="400px" 
                height="300px" 
                highlightedOptions={highlightedOptions}
                handleSelect={(questionnaire) => getQuestionnaire(questionnaire.uid)} />
        </div>
    )
}