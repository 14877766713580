import React from 'react'
import * as Yup from 'yup'
import Ajax from '../../../Ajax'
import { useFormik } from "formik"
import Field from '../fields/Field'
import { Button } from "@mui/material"
import { store } from "../../../stores/Store"
import { Icons } from '../../../misc/Icons'


export default function UpdatePasswordForm ({user}) {
    
    // non account managers can only update their own password, using a dedicated message
    if (user.uid === store.AppStore.sessionInfo.userUid) {
        return <UpdateOwnPassword />
    }
    
    // account managers can update anyones password
    if (store.AppStore.checkJwtRole("account_manager")) {
        return <UpdateAnyPassword user={user} />
    }
    
    return <p>You are not authorized to do this</p>
}


function UpdateOwnPassword () {

    const [success, setSuccess] = React.useState(false)
    const [revealPasswordText, setRevealPasswordText] = React.useState(false)

    var formik = useFormik({

        initialValues: {
            currentPassword: "",
            newPassword: "",
            confirmNewPassword: "",
        },

        validationSchema: Yup.object({
            currentPassword: Yup.string().required("This field if required"),
            newPassword: Yup.string().required("This field is required").matches(
                /(?!.*[`'"\\])(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{12,}/,
                "Your new password must contain at least 12 characters with 1 lowercase letter, 1 uppercase and one number. Banned characters are \\ ' \" and `")
        }),

        onSubmit: async (values) => {
            Ajax.Session.UpdatePassword(values).then(() => {
                setSuccess(true)
            }).catch((response) => {
                formik.setErrors({newPassword: response.data.detail})
            })
        }
    })

    if (success) return <p>Your password has been reset</p>

    return (
        <form autoComplete="off" className={`form`} onSubmit={formik.handleSubmit}>
            <h2>Update password</h2>

            <div className="form-fields">
                <Field.Text
                    formik
                    type="password"
                    label="Current password"
                    name="currentPassword"
                    onChange={formik.handleChange}
                    value={formik.values.currentPassword}
                    warning={formik.submitCount ? formik.errors.currentPassword : null} />

                <div style={{display: "grid", alignItems: "baseline", gridTemplateColumns: "auto 30px"}}>
                    <Field.Text
                        formik
                        label="Password"
                        name="newPassword"
                        type={revealPasswordText ? "text" : "password"}
                        autoComplete={false}
                        value={formik.values.newPassword}
                        warning={formik.submitCount && formik.errors.newPassword ? formik.errors.newPassword : false}
                        onChange={(e) => {
                            formik.setFieldValue("newPassword", e.target.value)
                        }} />
                        <Button className="btn btn-lg btn-icon" color="info" onClick={() => setRevealPasswordText(!revealPasswordText)}>
                            {revealPasswordText ? <Icons.Hide /> : <Icons.Show />}
                        </Button>    
                </div>
            </div>

            <div className="form-buttons">
                <Button
                    color="primary"
                    className="btn btn-sm"
                    onClick={() => store.AlertStore.Close()}>
                    Back
                </Button>
                <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    className="btn btn-sm"
                    disabled={formik.isSubmitting}>
                    Submit
                </Button>
            </div>

        </form>
    )
}


function UpdateAnyPassword ({user}) {
    
    const [revealPasswordText, setRevealPasswordText] = React.useState(false)

    var formik = useFormik({

        initialValues: {
            password: "",
        },

        validationSchema: Yup.object({
            password: Yup.string().required("This field is required").matches(
                /(?!.*[`'"\\])(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{12,}/,
                "Your new password must contain at least 12 characters with 1 lowercase letter, 1 uppercase and one number. Banned characters are \\ ' \" and `")
        }),

        onSubmit: async (values) => {

            var userPayload = {
                uid: user.uid,
                password: values.password,
                canUpdate: true,
                canEdit: true,
            }

            await Ajax.Node.Edit("user", userPayload).then(() => {
                store.AlertStore.Close()
                store.NodeStore.refreshLeafNode()
            }).catch((response) => {
                var message = response.data?.detail ? response.data?.detail : "An error occured"
                formik.setErrors({password: message})
            })
        }
    })

    return (
        <form autoComplete="off" className={`form`} onSubmit={formik.handleSubmit}>
            <h2>Update password</h2>
            
            <div className="form-fields">
                <div style={{display: "grid", alignItems: "baseline", gridTemplateColumns: "auto 30px"}}>
                    <Field.Text
                        formik
                        label="Password"
                        name="password"
                        type={revealPasswordText ? "text" : "password"}
                        autoComplete={false}
                        value={formik.values.password}
                        warning={formik.submitCount && formik.errors.password ? formik.errors.password : false}
                        onChange={(e) => {
                            formik.setFieldValue("password", e.target.value)
                        }} />
                        <Button className="btn btn-lg btn-icon" color="info" onClick={() => setRevealPasswordText(!revealPasswordText)}>
                            {revealPasswordText ? <Icons.Hide /> : <Icons.Show />}
                        </Button>    
                </div>
            </div>

            <div className="form-buttons">
                <Button
                    color="primary"
                    className="btn btn-sm"
                    onClick={() => store.AlertStore.Close()}>
                    Back
                </Button>
                <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    className="btn btn-sm"
                    disabled={formik.isSubmitting}>
                    Submit
                </Button>
            </div>
        </form>
    )
}