import React from 'react'
import * as Yup from 'yup'
import { useFormik } from "formik"
import Field from "./fields/Field"
import { useStore } from "../../stores/Store"
import { Button, CircularProgress } from "@mui/material"
import Ajax from '../../Ajax'


export default function LoginForm () {

    const {AppStore} = useStore()
    const [connectionOptions, setConnectionOptions] = React.useState<string[]>(null)

    var codeChallenge = AppStore.getCookie("AORA-OAuth-PKCE-code-challenge")
    var oauthProvider = AppStore.getCookie("AORA-OAuth-provider")
    var clientId = AppStore.getCookie("AORA-OAuth-client-id")
    var tenantId = AppStore.getCookie("AORA-OAuth-tenant-id")
    var redirectUri = AppStore.getCookie("AORA-OAuth-redirect-uri")
    redirectUri = decodeURI(redirectUri)

    var connectionSelect = window.location.host.includes("localhost") || ["dev", "staging"].includes(window.location.host.split(".")[0])

    React.useEffect(() => {
        if (connectionSelect) {
            Ajax.Session.ConnectionOptions().then((response) => {
                setConnectionOptions(response.data)
            })
        }
    }, [])
    
    const formik = useFormik({

        initialValues: {
            username: "",
            password: "",
            connectionName: ""
        },

        validationSchema: Yup.object({
            username: Yup.string().required("Please give your username"),
            password: Yup.string().required("Please give your password"),
            connectionName: connectionSelect ? Yup.string().required("Please select a server to connect to") : Yup.string().nullable()
        }),

        onSubmit: async (values, actions) => {

            values.password = values.password.trim()
            
            return await AppStore.login(values).catch((response) => {
                
                console.log(response)
                var errors: any = {}
                
                if (!response) {
                    errors.password = "Check your internet connection"
                } else if (response.data?.errors) {
                    Object.keys(response?.data?.errors).forEach(key => errors[key] = String(response.data?.errors[key]))
                } else if (response.data?.detail) {
                    errors.password = response.data?.detail
                } else {
                    errors.password = "Something went wrong"  
                }

                actions.setErrors(errors)
                
            })
        }
    })

    if (oauthProvider === "microsoft" && clientId && tenantId) {
        return (
            <div style={{width: "300px"}}>
                <h1>Sign in</h1>
                <p>Your account is configured to connect using Microsoft, click the button bellow to sign in.</p>
                <br/>
                <Button
                    className="btn btn-md"
                    color="info"
                    variant="contained"
                    style={{textTransform: "none"}}
                    href={`https://login.microsoftonline.com/${tenantId}/oauth2/v2.0/authorize?` +
                        `client_id=${clientId}&` +
                        `state=${oauthProvider}&` +
                        `redirect_uri=${redirectUri}&` +
                        "code_challenge=" + codeChallenge + "&" +
                        "scope=https%3A%2F%2Fgraph.microsoft.com%2Fopenid&" +
                        "response_type=code&" +
                        "code_challenge_method=S256"
                    }>
                    Sign in with 
                    <img alt="M" height="14" src="/images/microsoft.png" />
                </Button>
            </div>
        )
    }

    return (
        <form autoComplete="on" className="form form-login" onSubmit={formik.handleSubmit}>

            <h2>Sign in</h2>

            <div className="form-fields">
                
                {connectionSelect && connectionOptions ? (
                    <Field.Select
                        formik
                        name="connectionName"
                        label="Connection"
                        autoFocus={true}
                        autoComplete={true}
                        warning={formik.submitCount ? formik.errors.connectionName : null}
                        value={formik.values.connectionName}
                        onChange={formik.handleChange}
                        options={connectionOptions.map(connection => {
                            return {value: connection, label: connection}
                        })} />
                ) : null}

                <Field.Text
                    formik
                    name="username"
                    label="Username"
                    autoFocus={true}
                    autoComplete={true}
                    warning={formik.submitCount ? formik.errors.username : null}
                    value={formik.values.username}
                    onChange={formik.handleChange} />

                <Field.Text
                    formik
                    type="password"
                    name="password"
                    label="Password"
                    autoComplete={true}
                    warning={formik.submitCount ? formik.errors.password : null}
                    value={formik.values.password}
                    onChange={formik.handleChange} />
            </div>

            <div className="form-footer">
                <Button className="btn-md" disabled={formik.isSubmitting} type="submit" color="primary" variant="contained">
                    {!formik.isSubmitting ? "Sign in" : <CircularProgress />}
                </Button>

                
                {/* <small>Getting stuck? <a className="link" target="_blank" rel="noreferrer" href="https://teams.microsoft.com/dl/launcher/launcher.html?url=%2F_%23%2Fl%2Fmeetup-join%2F19%3Ameeting_ZGM0OTUyMDEtYWZjZS00ZTI3LThmMDctMDRkYzkxNzdlNzc0%40thread.v2%2F0%3Fcontext%3D%257B%2522Tid%2522%3A%2522f3ac26bd-42f9-4f2c-878b-9e61cda924d6%2522%2C%2522Oid%2522%3A%2522fe37d0b8-3f52-4d4e-a96e-c579bc4e4f17%2522%257D%26anon%3Dtrue&type=meetup-join&deeplinkId=30775937-3f59-4c5c-aebd-456314da5b3e&directDl=true&msLaunch=true&enableMobilePage=true&suppressPrompt=true">
                    Join the support hotline on Teams.
                </a></small> */}
            </div>
        </form>
    )
}