import React from "react"
import Ajax from "../../../Ajax"
import { Alert } from "../../.."
import { format } from "date-fns"
import { Button } from "@mui/material"
import { Icons } from "../../../misc/Icons"
import { store } from "../../../stores/Store"
import EnquirySettingsFormSection from "./EnquirySettingsFormSection"
import EnquiryStatusesFormSection from "./EnquiryStatusesFormSection"
import EnquiryGetOptionsFormSection from "./EnquiryGetOptionsFormSection"
import QuestionnaireSelectFormSection from "../questionnaire/QuestionnaireSelectFormSection"


interface CreateEnquiryFormProps {
    entityUid: string;
    hideStepsSection?: boolean;
}


export default function CreateEnquiryForm ({entityUid, hideStepsSection = false}: CreateEnquiryFormProps) {

    const formId = Math.random().toString()
    const [enquiry, setEnquiry] = React.useState(null)
    const [questionnaireId, setQuestionnaireId] = React.useState(null)
    const [step, setStep] = React.useState<number>(1)
    const [error, setError] = React.useState<string>("")
    const [submitting, setSubmitting] = React.useState(false)

    // Step 1: Get enquiry options for legal system
    const submitStep1 = async (values) => {

        setSubmitting(true)

        await Ajax.Enquiry.GetOptions(entityUid, values.ruleSystem, values.interestDate).then((response) => {

            setEnquiry({
                subjectUid: entityUid,
                interestDate: values.interestDate,
                ruleSystem: values.ruleSystem,
                statuses: response.data.childrenObjects.find(c => c.name === "STATUSES"),
                settings: response.data.childrenObjects.find(c => c.name === "SETTINGS")
            })
            setError("")
            setStep(2)

        }).catch(() => Alert({message: "Error getting enquiry options"}))

        setSubmitting(false)
    }

    // Step 2: Select statuses
    const submitStep2 = (statusesSection) => {
        setEnquiry({...enquiry, statuses: statusesSection})
        setStep(3)
    }

    // Step 3: Select statuses
    const submitStep3 = (questionnaireId) => {
        setQuestionnaireId(questionnaireId)
        setStep(4)
    }

    // Step 4: Define settings and create Enquiry
    const submitStep4 = async (settingsSection) => {

        setError("")
        setSubmitting(true)
        setEnquiry({...enquiry, settings: settingsSection})
        var data = {
            ...enquiry,
            settings: enquiry.settings.childrenObjects,
            statuses: enquiry.statuses.childrenObjects,
            questionnaireId: questionnaireId ? questionnaireId : null
        }

        console.log(data)

        return await Ajax.Enquiry.Create(data).then(response => {

            store.AlertStore.Close()
            store.NodeStore.refreshFolderNode()
            store.QuestionStore.initForm(null)

        }).catch((response) => {

            var message: string = "Something went wrong"

            // Handle basic string response
            if (typeof response?.data === "string") {
                message = response.data.toString()
                message.includes("interest date") && setStep(1)
            }

            // Handle .Net validation response
            if (response?.data?.detail) {
                message = response.data.detail
            }

            setError(message)
            setSubmitting(false)
        })
    }

    const AlternateFormSection = () => {
        switch (step) {
            case 1:
                return <EnquiryGetOptionsFormSection formId={formId} enquiry={enquiry} submit={submitStep1} />
            case 2:
                return <EnquiryStatusesFormSection formId={formId} onSubmit={submitStep2} section={enquiry.statuses} />
            case 3:
                return <QuestionnaireSelectFormSection formId={formId} value={questionnaireId} onSubmit={(qId) => submitStep3(qId)} />
            case 4:
                return <EnquirySettingsFormSection formId={formId} onSubmit={submitStep4} section={enquiry.settings} />
            default:
                return <></>
        }
    }


    const StepsSection = () => {

        const Step = ({label, stepNum}) => {
            return (
                <li style={{fontWeight: step === stepNum ? 600 : 300, cursor: "pointer"}} onClick={() => step > stepNum && setStep(stepNum)}>
                    {label} {step > stepNum ? <Icons.Tick style={{fontSize: "10px"}} /> : null}
                </li>
            )
        }

        return (
            <div>
                <ol style={{display: "grid", gap: "10px"}}>
                    <Step stepNum={1} label="Select legal system" />
                    <Step stepNum={2} label="Select statuses" />
                    <Step stepNum={3} label="Select questionnaire" />
                    <Step stepNum={4} label="Settings" />
                </ol>
            </div>
        )
    }


    return (
        <div className={`form ${submitting ? "disabled" : ""}`} style={{width: "80vw", maxWidth: "720px"}}>

            <div className="form-header">
                <h2>Create Enquiry</h2>
            </div>

            <div className="form-body">
                {hideStepsSection ? (
                    <AlternateFormSection />
                ) : (
                    <div style={{display: "grid", gridTemplateColumns: "180px 40px auto"}}>
                        <StepsSection />
                        <div style={{height: "100%", width: "1px", backgroundColor: "#e1e1e1", margin: "auto"}}></div>
                        <AlternateFormSection />
                    </div>
                )}
            </div>

            {error ? <div className="form-message warning">{error}</div> : null}

            <div className="form-buttons">
                {step > 1
                    ? <Button color="primary" className="btn btn-sm" onClick={() => setStep(step - 1)} >Back</Button>
                    : <Button color="primary" className="btn btn-sm" onClick={() => store.AlertStore.Close()} >Cancel</Button>}

                <Button
                    form={formId}
                    type="submit"
                    color={step === 4 ? "success" : "primary"}
                    variant="contained"
                    className="btn btn-sm">
                    {step === 4 ? "Submit" : "Next"}
                </Button>
            </div>

        </div>
    )
}