import { useState } from "react"
import { DisplayValue } from "../../.."
import { Icons } from "../../../misc/Icons"
import { SelectOption } from "./SelectField"
import { Button, FormLabel } from "@mui/material"
import InlineMenu from "../../../misc/InlineMenu"
import {Column, Table, AutoSizer} from 'react-virtualized'
import { InputFieldProps } from "../../../models/Interfaces"


interface LargeListSelectFieldProps extends InputFieldProps {
    options: SelectOption[];
    value?: any;
    width?: string;
    height?: string;
    multiple?: boolean;
    searchable?: boolean;
    noneOption?: boolean;
    minValueCount?: number;
    maxValueCount?: number;
}


export const MinimisedLargeListSelectField = ({
    onChange,
    options,
    name,
    value,
    label = "",
    width = "100%",
    height = "180px",
    warning = "",
    helpText = "",
    formik = false,
    multiple = true,
    disabled = false,
    autoFocus = false,
    searchable = true,
    noneOption = false,
    minValueCount = 0,
    maxValueCount = 1000,
    placeholder = "Search",
}: LargeListSelectFieldProps) => {

    var displayValue = multiple
        ? value.map(s => { return DisplayValue("countries", s) }).join(", ")
        : DisplayValue("countries", value)

    return (
        <div className={`field-large-list-select-minimised ${disabled ? "disabled" : ""}`}>
            
            {label ? <FormLabel>{label}</FormLabel> : null}

            <InlineMenu closeOnClick={false} opener={(
                <div style={{display: "grid", gridTemplateColumns: "18px auto", fontSize: "12px", alignItems: "center"}}>
                    <Button style={{padding: 0}} color="info" disabled={disabled}>
                        {maxValueCount > 1 ? <Icons.Plus style={{fontSize: "14px"}} /> : <Icons.Edit style={{fontSize: "14px"}} />}
                    </Button>
                    
                    {displayValue 
                        ? <span className="trunc-ellipsis">{displayValue}</span> 
                        : <span className="trunc-ellipsis font-grey">Select...</span>}
                </div>
            )} >
                <div style={{maxWidth: "200px", padding: "10px"}}>
                    <LargeListSelectField onChange={onChange} options={options} name={name} value={value} searchable={searchable} noneOption={noneOption} width={width} height={height} multiple={multiple} warning={warning} helpText={helpText} formik={formik} disabled={disabled} autoFocus={true} minValueCount={minValueCount} maxValueCount={maxValueCount} placeholder={placeholder} />
                </div>
            </InlineMenu>

            {warning ? <span className="warning">{warning}</span> : null}

            {helpText ? <span className="help-text">{helpText}</span> : null}
        </div>
    )
}



export const LargeListSelectField = ({
    onChange,
    options,
    name,
    value,
    label = "",
    width = "100%",
    height = "180px",
    warning = "",
    helpText = "",
    formik = false,
    multiple = true,
    disabled = false,
    autoFocus = false,
    searchable = true,
    noneOption = false,
    minValueCount = 0,
    maxValueCount = 1000,
    placeholder = "Search",
}: LargeListSelectFieldProps) => {

    if (!value) {
        value = multiple ? [] : ""
    }

    const sortAlphabetically = (l: SelectOption[]) => {
        return l.sort((a,b) => a.label.localeCompare(b.label))
    }

    options = sortAlphabetically(options)
    // "None of the above" is an option which is used for clearing the selection, its value won't be passed up
    if (noneOption === true) {
        options = [...options, {label: "None of the above", value: "NONE_OF_THE_ABOVE"}]
    }

    const [filter, setFilter] = useState<string>("")
    const [list, setList] = useState<SelectOption[]>(options)
    const [selection, setSelection] = useState(value)
    const [focusedRowIdex, setFocusedRowIndex] = useState(0)
    const [toolTip, setToolTip] = useState("")
    const [toolTipAnchor, setToolTipAnchor] = useState({x: 0, y: 0})


    const filterList = (e) => {
        setFilter(e.target.value)
        var filteredList = options.filter((item) => {
            return item.label.toLowerCase().includes(e.target.value.toLowerCase()) ? item : null
        })
        setList(filteredList)
        setFocusedRowIndex(0)
    }

    const toggleItem = (option: SelectOption) => {

        var newValue
        var alreadySelected

        if (option.value === "NONE_OF_THE_ABOVE") {
            newValue = []
        } else if (multiple && maxValueCount > 1) {
            alreadySelected = selection.find((selectedValue) => selectedValue === option.value)
            newValue = !alreadySelected
                ? [...selection, option.value] // Add
                : selection.filter(s => {return s !== option.value}) // Remove
        } else if (multiple && maxValueCount === 1) {
            alreadySelected = selection.find((selectedValue) => selectedValue === option.value)
            newValue = !alreadySelected 
                ? [option.value] // Add
                : [] // Remove
        } else {
            newValue = option.value
        }

        setSelection(newValue)
        setFocusedRowIndex(list.indexOf(option))

        handleChange(newValue)
    }

    const handleChange = (newList) => {

        // Make synthetic event
        var onChangeArg: any = {
            target: {
                name: name,
                value: newList
            }
        }

        onChangeArg = !formik 
            ? onChangeArg.target 
            : onChangeArg

        onChange(onChangeArg)
    }

    const handleKeyDown = (e) => {
        
        if (e.key === 'Enter' && e.ctrlKey) {
            toggleItem(list[focusedRowIdex])
            e.preventDefault()
        } else if (e.key === "ArrowDown" && focusedRowIdex <= list.length) {
            e.preventDefault()
            setFocusedRowIndex(focusedRowIdex + 1)
        
        } else if (e.key === "ArrowUp" && focusedRowIdex > 0) {
            e.preventDefault()
            setFocusedRowIndex(focusedRowIdex - 1)
        }
    }

    const revealTruncatedText = (e, maxWidth) => {
        
        maxWidth = maxWidth - 9  // Consider padding
        var targetRect = e.target.getBoundingClientRect()
        
        if (e.target.offsetWidth > maxWidth) {
            setToolTip(e.target.innerText)
            setToolTipAnchor({x: targetRect.left + 10, y: targetRect.bottom})
        }
    }

    const selectedLabels = multiple
        ? options.filter(o => value?.includes(o.value))
        : options.filter(o => value === o.value)

    return (
        <div className="form-field field-large-list-select" onKeyDown={handleKeyDown}>

            <div className="trunc-revealer" style={{
                display: toolTip ? "block" : "none",
                left: toolTipAnchor.x,
                top: toolTipAnchor.y
            }}>{toolTip}</div>

            {label ? <FormLabel style={{marginBottom: "5px"}}>{label}</FormLabel> : null}

            {searchable ? (
                <div className="list-filter">
                    <Icons.Search />
                    <input
                        type="text"
                        value={filter}
                        autoComplete="off"
                        onChange={filterList}
                        autoFocus={autoFocus}
                        placeholder={`${placeholder} (Ctrl+Enter to select)`} 
                        aria-label={`${label ? label : "Select field"} filter: ${options.length} options`} />
                </div>
            ) : null}

            <div className="large-list-select-list rv-table-md" style={{width: width, height: height}} onMouseLeave={(e) => setToolTip("")} >
                <AutoSizer>
                    {({height, width}) => (
                        <Table
                            width={width}
                            height={height}
                            rowHeight={27}
                            headerHeight={0}
                            rowCount={list.length}
                            scrollToIndex={focusedRowIdex}
                            tabIndex={-1}
                            rowGetter={({index}) => list[index]}
                            aria-label={`${label ? label : "Select field"}: ${list.length} options`}
                            noRowsRenderer={() => {
                                return <small style={{margin: "10px"}}>No results</small>
                            }}
                            rowRenderer={({rowData, key, style, className, index}) => { 

                                var selected = multiple 
                                    ? value?.includes(rowData.value) 
                                    : value === rowData.value

                                if (noneOption && rowData.value === "NONE_OF_THE_ABOVE" && selection.length === 0) {
                                    selected = true
                                }
                                var focused = focusedRowIdex === index
                                className = selected ? `${className} selected` : className
                                className = focused ? `${className} focused` : className

                                return (
                                    <div 
                                        key={key}
                                        tabIndex={-1}
                                        style={style}
                                        role="row"
                                        className={className} 
                                        onClick={() => toggleItem(rowData)}
                                        aria-label={`${rowData.label}: ${selected ? "selected" : ""}`} >

                                        <div onMouseLeave={(e) => setToolTip("")} onMouseEnter={(e) => revealTruncatedText(e, width)} className="ReactVirtualized__Table__rowColumn" role="cell">
                                            {rowData.label}
                                        </div>
                                    </div>
                                )
                            }}>
                            <Column width={-1} dataKey="label" />
                        </Table>
                    )}
                </AutoSizer>
            </div>

            {selectedLabels.length ? (
                <div className="selected-items">
                    {selectedLabels.map((option, i) => <SelectedOption key={i} option={option} toggleItem={toggleItem} />)}
                </div>
            ) : null}

            {warning !== false ? <span className="warning">{warning}</span> : null}

            {helpText ? <span className="help-text">{helpText}</span> : null}

        </div>
    )

}

function SelectedOption ({option, toggleItem}) {
    return (
        <>
            <span className="item" onClick={() => toggleItem(option)}>
                <span>
                    {option.label}<br/>
                    
                    {option.help && option.help.style === "URL" && (
                        <a href={option.help.details} target="_default" style={{textDecoration: "underline"}} onClick={(e) => e.stopPropagation()}>
                            {option.help.label}
                        </a>
                    )}
                </span>

                <Icons.X />
            </span>

            {option.help && option.help.style === "INLINE_TEXT" 
                ? <small style={{padding: "5px"}}>{option.help.details}</small>
                : null}
        </>
    )
}