// This form sequence is for getting a user from login to enquiry without navigation.
// ----------------------------------------------------------------------------------
// 1) What type of enquiry do you want to create, nationality or swv?
// 2) Select existing sponsor or create a new one
// 3) Create case
// 4) Create person
// 5) Create enquiry, skip status section by forcing SWV status

import * as Yup from 'yup'
import { Button } from "@mui/material"
import Field from "../fields/Field"
import React from "react"
import { useFormik } from "formik"
import { store } from "../../../stores/Store"
import { AccountCreateForm } from "../admin/AccountForms"
import { CaseCreateForm } from "../case/CaseForms"
import Ajax from "../../../Ajax"

export default function EnquirySWVSetupForm () {

    const [parentAccount, setParentAccount] = React.useState(null)
    const [caseUid, setCaseUid] = React.useState(null)

    if (!parentAccount) {
        return <ChooseEnquiryTypeAndAccount
            setParentAccount={setParentAccount} />
    }

    if (parentAccount === "NEW") {
        return <AccountCreateForm
            omitTitle
            guidance="Create a new Sponsor Client for your skilled worker case"
            initialValues={{
                accountType: "sponsor",
                countryId: "UK"
            }}
            onSuccess={(node) => {
                store.NodeStore.navigateNode(node?.uid)
                store.NodeStore.refreshAccounts()
                setParentAccount(node)
            }} />
    }

    if (!caseUid) {
        return <CaseCreateForm
            omitTitle
            guidance="For Skilled Worker cases, we suggest that you name your cases after the family name of the subject (job applicant)."
            // account={parentAccount}
            parentUid={parentAccount.uid}
            onSuccess={(caseNode) => {
                setCaseUid(caseNode.uid)
                store.NodeStore.navigateNode(caseNode.uid)
            }} />
    }
    
    return (
        <div>
            <p>Your case was created successfully! Start building the case by creating a person.</p>
            <br/>
            <Button variant="contained" className="btn btn-sm" onClick={() => {
                store.QuestionStore.initForm("CREATE_PERSON")
                store.AlertStore.Close()
            }}>Create a person</Button>
        </div>
    )
}


function ChooseEnquiryTypeAndAccount ({setParentAccount}) {

    const accountOptions = store.NodeStore.accounts.filter(a => a.typeName === "Aora.Nationality.Data.SponsorAccountNode").map(a => {
        return {label: a.label, value: a.uid}
    })

    const formik = useFormik({
        initialValues: {
            accountUid: accountOptions.length ? "" : "NEW", // If now account options, default to NEW
        },

        validationSchema: Yup.object({
            accountUid: Yup.string().required("You must choose an sponsor client")
        }),

        onSubmit: async (values) => {
            if (values.accountUid === "NEW") {
                setParentAccount("NEW")
            } else {
                await Ajax.Node.Get(values.accountUid).then((response) => {
                    setParentAccount(response.data)
                    store.NodeStore.navigateNode(values.accountUid)
                })
            }
        }
    })

    return (
        <form className="form" onSubmit={formik.handleSubmit}>
            
            <p>Skilled Worker Visa cases must be associated with a Sponsor Client.</p>

            {/* <Field.Select
                formik
                label="Select enquiry type"
                name="enquiryType"
                value={formik.values.enquiryType}
                onChange={formik.handleChange}
                options={[
                    {label: "Skilled Worker visa", value: "SWV"},
                    {label: "Nationality", value: "NAT"}
            ]} /> */}

            {accountOptions.length ? (
                <div className="form-fields">
                    <Field.Switch
                        label="Create new sponsor client"
                        value={formik.values.accountUid === "NEW"}
                        onChange={() => {
                            formik.setFieldValue("accountUid", formik.values.accountUid !== "NEW" ? "NEW" : "")
                        }} />

                    {formik.values.accountUid !== "NEW" ? <Field.Select
                        formik
                        label="Select an existing Sponsor Client"
                        name="accountUid"
                        warning={formik.errors.accountUid}
                        value={formik.values.accountUid}
                        onChange={formik.handleChange}
                        options={[...accountOptions]} />
                    : null}
                    
                    <div style={{display: "flex", justifyContent: "space-between", marginTop: "10px"}}>
                        <Button className="btn btn-sm" onClick={() => store.AlertStore.Close()}>Cancel</Button>
                        <Button className="btn btn-sm" variant="contained" type="submit" disabled={formik.isSubmitting}>Next</Button>
                    </div>
                </div>
            ) : (
                <>
                    <p>You currently have no Sponsor Client accounts so you must create a new one.</p>
                    <Button 
                        style={{marginTop: "10px"}} 
                        className="btn btn-sm btn-lowercase" 
                        variant="contained" 
                        type="submit" 
                        disabled={formik.isSubmitting}>
                        Create your first Sponsor Client
                    </Button>
                </>
            )}

        </form>
    )
}