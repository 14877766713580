import { Link } from "react-router-dom"
import { DisplayValue } from "../../.."
import { observer } from 'mobx-react-lite'
import { store } from '../../../stores/Store'
import { Icons, TypeIcon } from "../../../misc/Icons"
import { format } from "date-fns"
import NodeMenus from "../../../misc/NodeMenus"


export default observer(function FolderPanelListItem ({node}: any) {    
    
    var isStateSubject: boolean = store.AppStore.stateSubject === node.uid
    var classes = "panel-list-item"
    classes = isStateSubject ? `${classes} state-subject` : classes
    classes = node.uid === store.NodeStore.leafNode?.uid ? `${classes} selected` : classes
    var displayName = node.label ? node.label : node.uid
    var isLeafNode = [
        "Aora.Nationality.Data.SponsorNode",
        "Aora.Nationality.Data.PersonNode",
        "Aora.Platform.Data.EnquiryNode",
        "Aora.Platform.Data.UserNode"
    ].indexOf(node.typeName) > -1
    
    var displayType = store.NodeStore.getDisplayType(node)
    var link = !isLeafNode
        ? `/app/main/${node.uid}`
        : `/app/main/${store.NodeStore.folderNode.uid}/${node.uid}`

    return (
        <div className={classes}>
            <Link to={link} aria-label={`${displayType}: ${displayName}`}>
                <TypeIcon node={node} />
                <ListItemLabel node={node} />
                <ListItemStatus node={node} />
            </Link>
            {isLeafNode ? <Icons.ArrowRight /> : <NodeMenus node={node} />}
        </div>
    )
})


function ListItemStatus ({node}: any) {

    var isStateSubject: boolean = store.AppStore.stateSubject === node.uid    

    if (isStateSubject) {
        return <div className="status-indicator" style={{marginLeft: "auto"}}>{store.AppStore.stateAction}</div>
    }

    if (node.typeName === "Aora.Platform.Data.EnquiryNode") {
        return (
            <div 
                className="status-indicator" 
                style={{marginLeft: "auto", color: store.EnquiryStore.color(node)}}>
                {DisplayValue("enquiry_status", node.status)}
            </div>
        )
    }
    
    return <></>
}


function ListItemLabel ({node}: any) {    

    switch (node.typeName) {
        
        case "Aora.Platform.Data.EnquiryNode":
            
            var enquiry = node
            
            return (
                <div style={{display: "grid", paddingRight: "8px"}}>
                    <span className="trunc-ellipsis" style={{fontSize: "13px"}}>{store.NodeStore.getDisplayName(node)}</span>
                    <span className="trunc-ellipsis" style={{width: "auto", fontSize: "10px", color: "grey"}}>{store.EnquiryStore.getSubject(enquiry)?.name}</span>
                </div>
            )

        case "Aora.Nationality.Data.PersonNode":

            var dob = new Date(node.dateOfBirth)
            var dob_str = dob > new Date("1000-01-01") ? format(dob, "yyyy/MM/dd") : ""

            return (
                <div style={{display: "grid", paddingRight: "8px"}}>
                    <span className="trunc-ellipsis" style={{fontSize: "13px"}}>{store.NodeStore.getDisplayName(node)}</span>
                    <span className="trunc-ellipsis" style={{width: "auto", fontSize: "10px", color: "grey"}}>{dob_str} - {node.countryOfBirth}</span>
                </div>
            )
        
        case "Aora.Nationality.Data.SponsorNode":

            return (
                <span className="trunc-ellipsis">Jobs and sponsor info</span>
            )

        default:
            
            var label = store.NodeStore.getDisplayName(node)
            label = node.role ? `${label} ${node.role.join(", ")}` : label

            return (
                <span className="trunc-ellipsis">{label}</span>
            )
    }
}