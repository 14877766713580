import React from "react"
import Ajax from "../../../Ajax"
import { Alert } from "../../.."
import { Button } from "@mui/material"
import { store } from "../../../stores/Store"
import AutomationIdCheckForm, { AutomationIdCheckOTPForm } from "./AutomationIdCheckForm"
import QuestionnaireSelect from "../../views/questionnaire_mgmt/QuestionnaireTable"
import QuestionnaireDetails from "../../views/questionnaire_mgmt/QuestionnaireDetails"
import AutomationConfirmFormSection from "./AutomationConfirmFormSection"


export default function CreateAutomationForm ({onlineForm}) {

    const skipStep1And2 = !onlineForm.id.includes("ID_CHECK")
    const [step, setStep] = React.useState<number>(skipStep1And2 ? 3 : 1)
    const [additionalData, setAdditionalData] = React.useState(null)

    const goBack = () => {
        if (skipStep1And2 && step === 3) {
            store.AlertStore.Close()
        } else if (!skipStep1And2 && step === 1) {
            store.AlertStore.Close()
        } else {
            setStep(step - 1)
        }
    }

    const submitStep1 = async (values) => {
        setAdditionalData({...additionalData, ...values})
        setStep(2)
    }
    
    const submitStep2 = async (values) => {
        setAdditionalData({...additionalData, ...values})
        setStep(3)
    }

    const submitStep3 = (questionnaire) => {

        console.log(questionnaire)

        var newAdditionalData = {
            ...additionalData,
            questionnaires: []
        }

        if (questionnaire?.answers) {
            var questionnaireAnswers = JSON.parse(questionnaire.answers)
            if (questionnaireAnswers && typeof questionnaire.answers !== "object") {
                newAdditionalData = {
                    ...newAdditionalData,
                    ...questionnaireAnswers,
                    // Add questionnaire references to additional data so the questionnaires can be identified later.
                    questionnaires: [{value: questionnaire.uid, label: `${questionnaire.displayName}, ${questionnaire.template?.name}`}]
                }
            }
        }
        

        setAdditionalData(newAdditionalData)        
        setStep(4)
    }

    const submitStep4 = (values) => {
        try {
            var answers = {...additionalData, ...values}
            console.log(answers)
            store.AutomationStore.run(onlineForm.id, JSON.stringify(answers))
            store.AlertStore.Close()
        } catch {
            Alert({message: "Failed to run enquiry"})
        }
    }

    return (
        <div>
            <div className="form-header">
                <h2>Create Automation</h2>
            </div>

            <AutomationIdCheckForm hidden={step !== 1} onSubmit={submitStep1} onCancel={() => goBack()} />
            <AutomationIdCheckOTPForm hidden={step !== 2} onSubmit={submitStep2} onCancel={() => goBack()} />
            <AutomationQuestionnaireSelectFormSection hidden={step !== 3} onSubmit={(questionnaire) => submitStep3(questionnaire)} onCancel={() => goBack()} />
            <AutomationConfirmFormSection hidden={step !== 4} onSubmit={(values) => submitStep4(values)} onCancel={() => goBack()} formId={onlineForm.id} additionalData={additionalData} />
        </div>
    )
}





function AutomationQuestionnaireSelectFormSection ({
    hidden = true,
    onCancel = () => {},
    onSubmit = (questionnaire) => {}
}) {

    const [loading, setLoading] = React.useState(false)
    const [questionnaire, setQuestionnaire] = React.useState(null)

    const getQuestionnaire = async (uid) => {
        setLoading(true)
        
        await Ajax.Questionnaire.Get(uid).then((response) => {
            setQuestionnaire(response.data)
        }).catch(() => {
            Alert({message: "Failed to get questionnaire"})
        })
        
        setLoading(false)
    }

    if (hidden) return <></>

    if (questionnaire) { 
        return (
            <div className={loading ? "form disabled" : "form"} style={{paddingTop: "15px"}}>
                <QuestionnaireDetails questionnaire={questionnaire} />
    
                <div className="form-buttons">
                    <Button className="btn btn-sm" onClick={() => setQuestionnaire(null)}>Back</Button>
                    
                    <Button className="btn btn-sm" onClick={() => onSubmit(questionnaire)} color="success" variant="contained">
                        Use this questionnaire
                    </Button>
                </div>
            </div>
        )
    }

    return (
        <div className={loading ? "form disabled" : "form"}>
    
            <p>Select a questionnaire to feed the automation.</p>
            <QuestionnaireSelect height="400px" handleSelect={(q) => getQuestionnaire(q?.uid)} />
                
            <div className="form-buttons">
                <Button className="btn btn-sm" onClick={() => onCancel()}>Back</Button>
                
                <Button className="btn btn-sm" onClick={() => onSubmit(questionnaire)} color="success" variant="contained">
                    {questionnaire ? "Use this questionnaire" : "Continue without questionnaire"}
                </Button>
            </div>
        </div>
    )
}