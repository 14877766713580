import React from "react";
import TruncText from "../../../../../misc/TruncText";
import { DisplayDictionary, DisplayValue } from "../../../../..";
import { Button } from "@mui/material";
import Field from "../../../../form/fields/Field";

export default function AutomationAnwers ({automationNode}) {

    var outputData = automationNode.serialisedInstance
    outputData = JSON.parse(outputData)
    
    const valueStatuses = Object.keys(DisplayDictionary("automation_value_validity"))
    const [valueStatusFilter, setValueStatusFilter] = React.useState(valueStatuses)

    // Don't show page if there are no fields to show after filtering is applied
    var submittedPages = outputData?.submitted_pages.filter((page, i) => {

        var fields = page.fields.filter(f =>  valueStatusFilter.includes(f.value_status) && f.name)

        return !!fields.length
    })

    const printContainer = React.useRef(null)

    const print = () => {
        var WinPrint = window.open('#', '_blank', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
        
        if (printContainer?.current?.innerHTML) {
            var htmlHead = window.document.head.innerHTML
            htmlHead = htmlHead.replace("<script", "<!--<script")
            htmlHead = htmlHead.replace("</script>", "</script>-->")
            htmlHead = htmlHead.replace("<title", "<!--<title")
            htmlHead = htmlHead.replace("</title>", "</title>-->")
            
            WinPrint.document.write(`
                <html>
                    <head>
                        ${htmlHead}
                        <title>Automation Review</title>
                    </head>
                    <body>
                        <div class="automation-diagnostics">
                            ${printContainer.current.innerHTML}
                        </div>
                    </body>
                </html>
            `)
            WinPrint.document.close()
            WinPrint.focus()
            setTimeout(() => {
                WinPrint.print()
            }, 1000)
        }
    }

    return (
        <div className="automation-diagnostics">
            <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                <Field.MultipleSelect
                    checkboxes
                    name="field-filter"
                    label="Filter fields by validity of answer"
                    value={valueStatusFilter}
                    warning={false}
                    options={DisplayDictionary("automation_value_validity", true)} 
                    onChange={(e) => {
                        setValueStatusFilter(e.value)
                    }} />

                <Button className="btn btn-sm" variant="contained" color="info" onClick={() => print()}>Print PDF</Button>
            </div>

            <div className="diagnostics-field column-headers">
                <span>Field name</span>
                <span>Entered value</span>
                <span>Validity</span>
            </div> 

            <div style={{maxHeight: "500px", overflow: "auto"}}>
                <div ref={printContainer}>
                    {submittedPages.map((page, i) => (
                        <div key={i} className="diagnostics-section">
                            <h4>{i + 1}) {page.title}</h4>

                            {page.fields.filter(f => valueStatusFilter.includes(f.value_status) && f.name).map((field, i) => <FieldReadOnly key={i} field={field} /> )}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}



function FieldReadOnly ({field}) {

    if (!field.name) return <></>

	var fieldStatus = ""
	var label = field.label
        ? field.label.split("\n")[0]
        : field.name.charAt(0).toUpperCase() + field.name.slice(1).replaceAll("_", " ")

    if (["true", "false"].includes(field.value)) {
        field.value = field.value.replace("true", "Yes").replace("false", "No")
    }

	if (field.value_status) {
		switch (field.value_status) {
            case "GUESS":
                fieldStatus = "guessed-value" // Amber
                break
            case "EMPTY":
			case "FALSE":
				fieldStatus = "false-value" // Red
				break
			case "GENUINE":
				fieldStatus = "genuine-value"
				break
			default:
				fieldStatus = ""
				break
		}
	}

    var value = field.value

    

	return (
		<div className="diagnostics-field">
			<span>{label}</span>
			<span><TruncText style={{color: "grey", fontWeight: "300"}} text={value} /></span>
			<span className={fieldStatus}>{DisplayValue("automation_value_validity", field.value_status)}</span>
		</div>
	)
}