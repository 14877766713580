import React from "react";
import { store } from "../../../stores/Store";
import { observer } from "mobx-react-lite";
import { Button } from "@mui/material";
import ViewQuestionnaire from "../questionnaire_mgmt/ViewQuestionnaire";
import { AlertModal } from "../../..";

export default observer(function QuestionLog () {

    const { loadedQuestionnaireUid, loadedQuestionnaireUsage, loadedQuestionnaireLabel } = store.QuestionnaireStore

    const ref = React.useRef(null)

    React.useEffect(() => {
        if (ref.current) {
            ref.current.scrollTo(0, ref.current.scrollHeight)
        }
    }, [])

    if (!loadedQuestionnaireUid) return <h3>No questionnaire loaded</h3>

    return (
        <div className="question-log">
            <h2>Loaded Questionnaire</h2>

            <p>
                Answers provided from 
                Questionnaire <span
                    className="link" 
                    onClick={() => AlertModal({width: "700px", body: <ViewQuestionnaire uid={loadedQuestionnaireUid} />})}>
                    {loadedQuestionnaireLabel}
                </span>:
            </p>

            <div className="question-log-content" ref={ref}>
                {loadedQuestionnaireUsage?.map((ir, i) => (
                    <div key={i}>
                        <p className="message">{ir.question}</p>
                        <p className="message answer">{ir.answer}</p>
                    </div>
                ))}
            </div>
        </div>
    )
})