import ErrorBoundary from "../../../../../misc/ErrorBoundary";
import { store } from "../../../../../stores/Store";
import Automation from "./Automation";
import AutomationCta from "./AutomationCta";


export default function AutomationSection () {
    
    var { enquiryAutomations } = store.AutomationStore

    return (
        <ErrorBoundary>
            <div className="detail-section">
                <div className="section-header">
                    <h4>Automations</h4>
                </div>

                <AutomationCta />
                
                {enquiryAutomations?.length ? (
                    <>
                        <div className="section-subheader">
                            <h5>Previous automations</h5>
                        </div>

                        {enquiryAutomations.map((aNode, i) => <Automation key={i} automationNode={aNode} />)}
                    </>
                ) : null}
            </div>
        </ErrorBoundary>
    )
}