import React from "react"
import { configure } from "mobx"
import AlertStore from "./AlertStore"
import { AppStore } from "./AppStore"
import { NodeStore } from "./NodeStore"
import { ToolStore } from "./ToolStore"
import { ReportStore } from "./ReportStore"
import { EnquiryStore } from "./EnquiryStore"
import { QuestionStore } from "./QuestionStore"
import { ConnectionStore } from "./ConnectionStore"
import { AutomationStore } from "./AutomationStore"
import { QuestionnaireStore } from "./QuestionnaireStore"


configure({enforceActions: "never"})


export const store = {
    AppStore: new AppStore(),
    ToolStore: new ToolStore(),
    AlertStore: new AlertStore(),
    ConnectionStore: new ConnectionStore(),

    NodeStore: new NodeStore(),
    ReportStore: new ReportStore (),
    EnquiryStore: new EnquiryStore(),
    QuestionStore: new QuestionStore(),
    QuestionnaireStore: new QuestionnaireStore(),
    AutomationStore: new AutomationStore()
}

export const storeContext = React.createContext(store)

export function useStore () {
    return React.useContext(storeContext)
}