import React from "react"
import * as Yup from 'yup'
import { Alert } from "../../.."
import Ajax from "../../../Ajax"
import { useFormik } from "formik"
import Field from "../fields/Field"
import { Button } from "@mui/material"
import { store } from "../../../stores/Store"


interface CaseEditFormProps {
    caseNode: any;
    onSuccess?: Function;
}

interface CaseCreateFormProps {
    parentUid?: string;
    guidance?: string;
    omitTitle?: boolean;
    onSuccess?: Function;
}

export function CaseEditForm ({caseNode, onSuccess = (node) => {}}: CaseEditFormProps) {
    return (
        <div>
            <h2>Edit Case</h2>
            <CaseFormOnly
                initialValues={{
                    name: caseNode.name
                }}
                handleCancel={() => store.AlertStore.Close()}
                handleSubmit={async (values) => {
                    var caseWithNewName = {...caseNode, name: values.name}
                    await Ajax.Node.Edit("case", caseWithNewName).then((response) => {
                        onSuccess(response.data)
                    }).catch((response) => {
                        console.log("response", response)
                        Alert({message: response.data?.detail ? response.data?.detail : "Couldn't get owner"})
                    })
                }} />
        </div>
    )
}

export function CaseCreateForm ({parentUid, guidance = null, omitTitle = false, onSuccess = (node) => {}}: CaseCreateFormProps) {
    return (
        <div>
            {!omitTitle ? <h2>Create Case</h2> : null}

            <CaseFormOnly
                guidance={guidance}
                handleCancel={() => store.AlertStore.Close()}
                handleSubmit={ async (caseNode) => {
                    await Ajax.Node.Create("case", parentUid, caseNode).then(response => {
                        onSuccess(response.data)
                    }).catch((response) => {
                        console.log("response", response)
                        Alert({message: response.data?.detail ? response.data?.detail : "Couldn't get owner"})
                    })
                }} />
        </div>
    )
}


export default function CaseFormOnly ({
    handleCancel,
    handleSubmit,
    guidance = null,
    initialValues = {
        "name": ""
    }
}) {

    var formik = useFormik({

        initialValues: initialValues,

        validationSchema: Yup.object({
            name: Yup.string().matches(/^[ ,.@!$%#';:?()[\]~_+=À-Ö\p{Sc}\p{So}\p{Mn}\p{P}\p{Z}À-ÿ\w\n]*$/, "You have used some illegal characters").required("You must prvide a name")
        }),

        onSubmit: async (values) => {
            await handleSubmit(values)
        }
    })

    return (
        <form className="form" onSubmit={formik.handleSubmit}>
            <div className="form-fields">

                {guidance ? <p>{guidance}</p> : null}

                <Field.Text
                    formik
                    autoFocus
                    label="Case name"
                    name="name"
                    disabled={formik.isSubmitting}
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    warning={formik.submitCount && formik.errors.name ? formik.errors.name : false}
                />
            </div>

            <div className="form-buttons">
                <Button
                    disabled={formik.isSubmitting}
                    onClick={() => handleCancel()}
                    className="btn btn-sm">
                    Cancel
                </Button>

                <Button
                    type="submit"
                    variant="contained"
                    disabled={formik.isSubmitting}
                    className="btn btn-sm">
                    Submit
                </Button>
            </div>
        </form>
    )
}

